// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import './b-cabinet-order-travel-policy-status/style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import approvalTemplate from './template-approval-popup.ejs';
import paymentInHotelTemplate from './b-cabinet-order-payment/template-payment-in-hotel.ejs';
import RouteInfoViewAvia from '@/blocks/elements/b-ticket/b-ticket-route.ejs';
import RouteInfoViewTrain from '@/blocks/elements/b-train/b-order/b-route-info/index.js';
import HotelInfoView from '@/blocks/elements/b-hotel/b-hotel-order/b-hotel-info/index.js';
import TransferInfoView from '@/blocks/elements/b-transfer/b-transfer-order/b-transfer-info/index';
import DocoDoca from '@/blocks/pages/b-cabinet/b-cabinet-docodoca';
import PaymentView from './b-cabinet-order-payment/index.js';
import PricingView from './b-cabinet-order-pricing/index.js';
import PdfViewerModal from './b-cabinet-order-pdf-viewer-modal/index.js';
import OrderNotes from './b-cabinet-order-notes/index.js';
import BookingDocsModal from './b-cabinet-order-booking-docs-modal/index.js';
import AdditionalServicesView from '@/blocks/elements/b-hotel/b-hotel-additional-services/index';
import RefundExchangeModalView from './b-cabinet-order-refund-exchange/index';
import AncillaryFeesView from './b-cabinet-order-ancillary-fees/index';
import AuthorizationStepsModalView from './b-cabinet-order-authorization-steps/index.js';
import EmailsCollectionView from '@/blocks/elements/b-ticket/b-ticket-popup-offers/b-emails-collection/index';
import EmailsCollection from '@/blocks/elements/b-ticket/b-ticket-popup-offers/b-emails-collection/collection';
import EmailModel from '@/blocks/elements/b-ticket/b-ticket-popup-offers/b-emails-collection/b-email/model';

import BaseModel from '@/classes/base.model';
import Formatter from '@/utils/formatter';
import Backbone from 'backbone';

import axios from 'axios';
import $ from 'jquery';
import GlUl from '@/utils/global-utils';

export default BaseView.extend({

	template,

	events: {
		'click .b-route-receipt-link': 'showRouteReceipt',
		'click .b-refund-services': 'refundServices',
		'click .b-exchange-services': 'exchangeServices',
		'click .b-change-ereg-print': 'changeEregPrint',
		'click .b-change-ereg-send': 'changeEregSend',
		'click .b-save-notes': 'saveNotes',
		'click .b-expand': 'expandContainer',
		'change [name="initiatePayment.paymentType"]': 'renderPricingInfo',
		'click .b-payment__cancel': 'cancelBooking',
		'click .b-payment__void': 'voidBooking',
		'click .b-payment__approval': 'approvalBooking',
		'click .b-payment__authorization': 'sendToAuthorization',
		'click .js-create-client-finance-documents': 'createFinanceDocuments',
		'click .js-create-subagent-finance-documents': 'createFinanceDocuments',
		'click .js-get-client-finance-documents': 'getFinanceDocuments',
		'click .js-get-subagent-finance-documents': 'getFinanceDocuments',
		'click .js-show-booking-docs-widget': 'showBookingDocumentsWidget',
		'click .b-order__intention-approval': 'selectIntention',
		'click .b-booking-authorization': 'bookingAuthorization',
		'click .b-order__refuse_auth': 'refuseAuthorization',
		'click .b-order__approve_auth': 'approveAuthorization',
		// flight receipts buttons
		'click .b-get-flight-receipt': 'getFlightReceipt',
		'click .b-download-flight-receipt': 'downloadFlightReceipt',
		'click .b-flight-services__rule-carrier-open': 'openRuleCarrier',
		'click .js-footer-controls-btn': 'mobileFooterControlsHandler',
		'click .js-show-mobile-controls': 'showMobileControls',
		// ancillary fees
		'click .js-switch-tab': 'switchLabelTab',
		'click .js-getAncillaryFees': 'showAncillaryFeesModal',
		'click .js-cancelAncillaryFees': 'cancelAncillaryFees',
		'click .js-copy-to-clipboard': 'copyToClipboard',
		'click .js-open-auth-steps-modal': 'openAuthStepsModal',
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.adjustMobileTemplate.bind(this),
			isMatchMedia: true,
		});
	},

	initialize() {
		const { order, messages, parentView } = this.options;

		this.selectedTab = 'TICKETS';
		this.selectedServices = {};
		this.selectedServicesAvia = {};
		this.selectedMcoServicesAvia = {};
		this.selectedReservation = {};
		this.selectedApprovalNeeded = false;
		this.selectedIssueAllowed = false;
		this.model = this.options.model;
		this.parentView = parentView;
		this.model.set('notes', order.notes);
		this.notFullFilledOrder = _.some(messages, (m) => m.code === 'notFilled');
		this.storedMessages = STORE.get('orderBookingMessages')?.messages;

		if (!_.isEmpty(this.storedMessages)) {
			this.storedMessages.forEach((message) => {
				if (message.code === 'priceChanged') {
					const popup = new Widgets.Popup({
						content: message.text,
						type: 'info',
						title: L10N.get('cabinet.orders.priceChanging'),
						actions: [{
							label: L10N.get('settings.errorPopupButton'),
							action: () => {
								popup.hide();
							},
						}],
						onClose: () => {
							popup.hide();
							STORE.remove('orderBookingMessages');
						},
					});
					popup.show();
				}
			});
		}

		if (this.notFullFilledOrder || _.some(order.reservations || [], el => el && el.incomplete)) this.makeSafeData(order);

		order.reservations = _.sortBy(this.groupServices(order.reservations), (reservation) => {
			if (reservation.status.uid === 'CANCELED') {
				return 1;
			}
			return 0;
		});

		_.each(order.reservations, (reservation, index) => {
			reservation._index = index;
			reservation.services = reservation.services.length === 0 ? [] : _.sortBy(reservation.services, (service) => {
				if (_.some(service.travellers, (traveller) => traveller && ['YOUTH', 'SENIOR', 'ADULT'].includes(traveller.travellerType))) {
					return 0;
				} else if (_.some(service.travellers, (traveller) => traveller && traveller.travellerType === 'CHILD')) {
					return 1;
				} else if (_.some(service.travellers, (traveller) => traveller && ['INFANT_WITH_SITE', 'INFANT'].includes(traveller.travellerType))) {
					return 2;
				}

				return 3;
			});
		});

		const availableServices = _.map(STATE.getSettings().corporateSettings.availableServices, (el) => el && el.uid);
		let additionalServices = [];
		if (order.addAirServiceAllowed) {
			additionalServices.push({
				uid: 'AVIA',
				caption: L10N.get('cabinet.orders.serviceTypes.AVIA'),
			});
		}
		if (order.addRailServiceAllowed) {
			additionalServices.push({
				uid: 'RAIL',
				caption: L10N.get('cabinet.orders.serviceTypes.RAIL'),
			});
		}
		if (order.addHotelServiceAllowed) {
			additionalServices.push({
				uid: 'HOTEL',
				caption: L10N.get('cabinet.orders.serviceTypes.HOTEL'),
			});
		}
		if (order.addTransferServiceAllowed) {
			additionalServices.push({
				uid: 'TRANSFER',
				caption: L10N.get('cabinet.orders.serviceTypes.TRANSFER'),
			});
		}
		additionalServices = _.filter(additionalServices, (el) => availableServices.includes(el.uid));

		// this.model.set('needAuthorization', order.needAuthorization ? order.needAuthorization : false);

		const persons = [];

		_.each(order.reservations, (r) => {
			if (!_.isEmpty(order.reservations)) {
				_.each(r.services, (p) => {
					if (!_.isEmpty(r.services)) {
						_.each(p.travellers, (t) => {
							if (t && !_.isEmpty(t.person)) persons.push(t.person);
						});
					}
				});
			}
		});

		this.passengersToSend = _.isEmpty(persons) ? persons : _.uniq(persons, (p) => p.uid);
		this.clientToSend = order.client;

		if (STATE.checkSiteType('B2B')) GlUl.getEmails.call(this);
		this.options.order.allReservationHasErrors = order.reservations.every(r => r && r.status && r.status.uid === 'ERROR');
		const offersWithOnlineParam = STATE.get('offersWithOnlineParam');
		if (offersWithOnlineParam) {
			this.checkOnlineParamIsChanged(offersWithOnlineParam);
		}
		this.render({
			notFullFilledOrder: this.notFullFilledOrder,
			ancillaryFeesAvailable: this.ancillaryFeesAvailable,
			selectedTab: this.selectedTab,
			additionalServices,
			messages,
		});
		$(window).on('resize', this.resize.bind(this, null));
		const isMobile = STATE.checkViewport('(max-width: 768px)');
		if (isMobile) {
			this.$('.b-textarea__value').on('input paste', this.textareaDynamicHeightHandler.bind(this));
			$(window).on('click', this.mobileFooterControlsOutsideClickHandler.bind(this));
		}
		_.defer(() => {
			this.resize.bind(this, null);
			if (isMobile) {
				this.adjustMobileFooterBtns();
				this.mobileOrderFooterControlsInit();
				this.mobileReservationFooterControlsInit();
				this.textareaDynamicHeightHandler.bind(this);
			}
		});
	},

	initializeTimeLimitCounter(reservations) {
		_.each(reservations, (r, i) => {
			const $reservation = this.$(`[data-reservation-id="${i}"]`);
			if (Math.floor(r.timeExpirationMinutes / 60) > 24) {
				$reservation.find('.js-expiration-count-container').remove();
				return;
			}
			const $container = $reservation.find('.js-expiration-count');

			const getTime = (minutes) => {
				return Formatter.formatDurationFromPattern(minutes);
			};
			if (r.timeExpirationMinutes <= 0) return;
			this[`timeLeft${i}`] = r.timeExpirationMinutes;
			$container.html(getTime(r.timeExpirationMinutes));
			this[`timeLimitInterval${i}`] = setInterval(() => {
				const timeLeft = parseInt(this[`timeLeft${i}`]) - 1;
				this[`timeLeft${i}`] = timeLeft;
				if (timeLeft === 0 || isNaN(timeLeft)) {
					clearInterval(this[`timeLimitInterval${i}`]);
					delete this[`timeLeft${i}`];
					delete this[`timeLimitInterval${i}`];
					return;
				}

				const newTime = getTime(timeLeft);

				$container.html(newTime);
			}, 1000 * 60);
		});
	},

	textareaDynamicHeightHandler(e) {
		if (!e) return;
		const element = $(e.currentTarget);
		element.css({height: '5px'});
		element.css({height: `${element.prop('scrollHeight')}px`});
	},

	render(...args) {
		const {order, booking} = this.options;
		const {reservations} = order || {};

		const sellReservations = [];
		const needAuthorizationReservations = [];
		const authorizationRefusedReservations = [];

		_.each(reservations, (r) => {
			if (!r) return;
			if (r.travelSubject.uid === 'HOTEL_RESERVATION' && r.status.uid === 'PROPOSAL') return;
			if (r.issueAllowed) sellReservations.push(r);
			// if (r.needAuthorization) needAuthorizationReservations.push(r);
			if ((r.status && r.status.uid === 'AUTHORIZATION')
				&& (r.travelPolicyStatus && r.travelPolicyStatus.uid === 'AUTHORIZATION_NEEDED')) {
				needAuthorizationReservations.push(r);
			}
			// if (r.authorizationRefused) authorizationRefusedReservations.push(r);
			if (r.travelPolicyStatus && r.travelPolicyStatus.uid === 'AUTHORIZATION_NOT_APPROVED') authorizationRefusedReservations.push(r);
		});

		//XTR-13260 for default select all if all reservations neeed authorization

		const allReservationsNeedAuthorization = needAuthorizationReservations.length === _.size(reservations);

		// IBECORP-3389
		// Check for all Intentions and check every for "approvalNeeded"
		if (reservations != null) {
			this.isAllIntentions = reservations.slice()
				.filter((reservation) => !['ERROR', 'CANCELED', 'REFUND', 'ISSUED'].includes(reservation.status.uid))
				.every((reservation) => `approvalNeeded` in reservation && reservation.approvalNeeded);	
			_.each(reservations, (reservation, index) => {
				const reservationModel = new BaseModel({
					uid: reservation.uid,
					approvalNeeded: !!reservation.approvalNeeded,
					issueAllowed: !!reservation.issueAllowed,
					// authorizationNeeded: !!reservation.authorizationNeeded,
					needAuthorization: !!(reservation.travelPolicyStatus && reservation.travelPolicyStatus.uid === 'AUTHORIZATION_NEEDED'),
					// authorizationRefused: !!reservation.authorizationRefused,
					authorizationRefused: !!(reservation.travelPolicyStatus && reservation.travelPolicyStatus.uid === 'AUTHORIZATION_NOT_APPROVED'),
					selected: this.isAllIntentions ? reservation.approvalNeeded || false : (reservation.issueAllowed || allReservationsNeedAuthorization),
					disabled: this.isAllIntentions ? !reservation.approvalNeeded || false : (!reservation.issueAllowed),
				});
				this.model.set(`selectedReservation.${index}`, reservationModel);
			});
		}
		BaseView.prototype.render.apply(this, args);

		if (!_.isEmpty(reservations)) this.initializeTimeLimitCounter(reservations);

		if (STATE.checkSiteType('B2B') && !_.isEmpty(booking)) {
			this.orderNotes = new OrderNotes({
				booking: {
					uid: this.model.get('initiatePayment').get('bookingToPay'),
					caption: '',
				},
			});
			this.$el.find('.b-order__notes').html(this.orderNotes.$el);
		}
		if (_.size(sellReservations) > 1) {
			this.$el.find('.b-order__payment .b-order__payment-hidden').hide();
			this.$el.find('.b-order__payment .b-payment__approval').hide();
		}

		if (allReservationsNeedAuthorization) {
			this.$el.find('.b-order__payment .b-order__payment-hidden').hide();
			this.$el.find('.b-order__payment .b-payment__approval').hide();
			this.$el.find('.b-order__payment .b-payment__authorization').show();
		}

		if (order.addServiceDisabled) {
			this.$el.find('.b-order__additional-select').hide();
		}

		if (order.allReservationHasErrors || authorizationRefusedReservations.length === _.size(reservations)) {
			this.$el.find('.b-order__payment .b-order__payment-hidden').hide();
			this.$el.find('.b-order__payment .b-payment__approval').hide();
			// this.$el.find('.b-order__payment .b-payment__authorization').hide();
		}

		_.each(reservations, (reservation, index) => {
			const $reservationContainer = this.$el.find('.b-order-reservation').filter(`[data-reservation-id="${index}"]`);
			const reservationModel = this.model.get(`selectedReservation.${index}`);

			this.selectedReservation[index] = reservationModel;

			this.selectedReservation[index].set('status', reservation.status.uid);
			this.selectedReservation[index].set('travelSubject', reservation.travelSubject.uid);

			reservation.statuses = reservation.statuses || [];
			reservation.statuses.push(reservation.status);
			reservation.statuses = [...new Set(reservation.statuses)];

			if (_.every(reservation.statuses, (s) => ['CANCELED', 'REFUND'].includes(s))) {
				$reservationContainer.addClass('disabled');
			} else {
				$reservationContainer.removeClass('disabled');
			}

			this.listenTo(reservationModel, 'change:selected', this.toggleSelectReservations.bind(this, index));
			if (reservation.travelSubject && reservation.travelSubject.uid === 'AIR') {
				/* Route info */
				this.$('.b-expand').show();
				if (reservation && !_.isEmpty(reservation.legs)) {
					$reservationContainer.find('.b-order__container-expand-inner').html(RouteInfoViewAvia({
						flight: reservation,
						withServices: true,
						isOnline: order.online,
					}));
				} else {
					this.$('.b-expand').hide();
				}

				/* DOCO/DOCA */
				const docoDocaInfo = reservation.docoDocaNeededInfo;
				const docoDocaNeeded = docoDocaInfo != null && (docoDocaInfo.isDocaNeeded || docoDocaInfo.isDocoNeeded);
				const passengersHaveVisa = _.some(reservation.services, (el) => {
					if (!el) return false;
					return _.some(el.travellers, (traveller) => traveller && (!_.isEmpty(traveller.docoInfos) || !_.isEmpty(traveller.docaInfos)));
				});

				const isVisaNeeded = docoDocaNeeded || passengersHaveVisa;

				if (isVisaNeeded) {
					$reservationContainer.find('.b-order__docodoca-container').html(new DocoDoca({
						bookingUid: booking.bookingInfo.bookingUid,
						data: {
							reservation: {
								uid: reservation.uid,
								passengers: _.reduce(reservation.services, (a, b) => a && b && a.concat(b.travellers), []),
								docoDocaNeededInfo: docoDocaInfo,
							},
						},
					}).$el);
				}

				// flight receipts checkbox model + listener
				// ----------------------------
				const hasCompletedFlight = _.some(reservation.services, (el) => (el && el.certificateOfCompletedFlightCreationPossible === true));
				const isRefundable = _.some(reservation.services, (el) => (el && el.refundable === true));

				if (hasCompletedFlight === true || reservation.isVoidBookingAllowed || isRefundable) {
					this.model.set(`selectedServicesAvia.${index}`, new BaseModel({
						allServices: false,
					}));

					this.selectedServicesAvia[index] = this.model.get(`selectedServicesAvia.${index}`);

					_.each(reservation.services, (el) => {
						if (!el) return;
						const model = new BaseModel({
							selected: false,
							disabled: false,
						});
						this.selectedServicesAvia[index].set(el.uid, model);
						this.listenTo(model, 'change:selected', this.toggleSelectServiceAvia.bind(this, index));
					});

					this.listenTo(this.selectedServicesAvia[index], 'change', this.toggleSelectServiceAvia.bind(this, index));
					$reservationContainer.find('.b-payment__void').addClass('btn-disabled');
				}

				if (isRefundable) $reservationContainer.find('.b-refund-services').addClass('btn-disabled');

				if (!_.isEmpty(reservation.mcoServices)) {
					this.model.set(`selectedMcoServicesAvia.${index}`, new BaseModel({
						allServices: false,
					}));

					this.selectedMcoServicesAvia[index] = this.model.get(`selectedMcoServicesAvia.${index}`);

					_.each(reservation.mcoServices, (s) => {
						if (!s) return;
						const model = new BaseModel({
							selected: false,
							disabled: false,
						});
						this.selectedMcoServicesAvia[index].set(s.uid, model);
						this.listenTo(model, 'change:selected', this.toggleSelectedMcoServicesAvia.bind(this, index));
					});
					this.listenTo(this.selectedMcoServicesAvia[index], 'change', this.toggleSelectedMcoServicesAvia.bind(this, index));
				}
				// ---------------------------
				// flight receipts checkbox model + listener
			} else if (reservation.travelSubject.uid === 'RAILWAY') {
				const hasChangeERegAvailable = _.some(reservation.services, (el) => (el && el.changeERegAvailable === true));
				const hasRefundableService = _.some(reservation.services, (el) => (el && el.refundable === true));
				const hasEditingSerivce = hasChangeERegAvailable || hasRefundableService;

				/* Route info */

				$reservationContainer.find('.b-order__container-expand-inner').html(new RouteInfoViewTrain({
					reservation,
				}).$el);

				if (hasEditingSerivce === true) this.addSelectedServicesListeners(reservation, index);
				if (hasRefundableService) $reservationContainer.find('.b-refund-services').addClass('btn-disabled');
			} else if (reservation.travelSubject.uid === 'HOTEL_RESERVATION') {
				const hasRefundableService = _.some(reservation.services, (el) => (el && el.refundable === true));
				const hasExchangeableService = _.some(reservation.services, (el) => (el && el.exchangeable === true));
				const hasEditingService = hasExchangeableService || hasRefundableService;
				/* Hotel info */

				$reservationContainer.find('.b-order__container-expand-inner').html(new HotelInfoView({
					reservation,
				}).$el);

				if (reservation.services && _.size(reservation.services) !== 0 && reservation.services[0].offer != null) {
					$reservationContainer.find('.b-order__additional-services-container').html(new AdditionalServicesView({
						offer: reservation.services[0].offer,
						index,
					}).$el);
				}
				if (hasEditingService) {
					this.addSelectedServicesListeners(reservation, index);
					$reservationContainer.find('.b-refund-services').addClass('btn-disabled');
				}
			} else if (reservation.travelSubject.uid === 'TRANSFER') {
				const hasRefundableService = _.some(reservation.services, (el) => (el && el.refundable === true));

				if (hasRefundableService === true) {
					this.addSelectedServicesListeners(reservation, index);
					$reservationContainer.find('.b-refund-services').addClass('btn-disabled');
				}

				$reservationContainer.find('.b-order__container-expand-inner').html(new TransferInfoView({
					reservation,
				}).$el);
			}
		});

		this.toggleSelectAllReservations();
		this.bindWidgets();
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
	},

	addSelectedServicesListeners(reservation, reservationIndex) {
		this.model.set(`selectedServices.${reservationIndex}`, new BaseModel({
			allServices: false,
		}));

		this.selectedServices[reservationIndex] = this.model.get(`selectedServices.${reservationIndex}`);
		_.each(reservation.services, (el) => {
			if (!el) return;
			const model = new BaseModel({
				selected: false,
				disabled: false,
			});
			this.selectedServices[reservationIndex].set(el.uid, model);
			this.listenTo(model, 'change:selected', this.toggleSelectService.bind(this, reservationIndex));
		});

		this.listenTo(this.selectedServices[reservationIndex], 'change', this.toggleSelectService.bind(this, reservationIndex));
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		$(window).off('click', this.mobileFooterControlsOutsideClickHandler.bind(this));
		$(window).off('resize', this.resize.bind(this, null));
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	checkOnlineParamIsChanged(offers) {
		const reservationsWithChangedParam = [];
		const reservations = this.options.order.reservations;
		const lastReservation = reservations[reservations.length - 1];
		if (lastReservation && lastReservation.isOnlineBooking !== offers[0].online) {
			reservationsWithChangedParam.push(lastReservation);
		}
		if (reservationsWithChangedParam.length) {
			const prnsOfReservations = reservationsWithChangedParam.map((reservation) => reservation.prn);
			const message = prnsOfReservations.length === 1 ? `Внимание! Информируем Вас о том, что бронирование номера возможно только под запрос.`
				: `Внимание! Информируем Вас о том, что бронирование номеров ${prnsOfReservations.join(', ')} возможно только под запрос.`;
			const infoPopup = new Widgets.Popup({
				content: message,
				type: 'info',
				classes: 'hello',
				maskClasses: 'hello',
				actions: [{
					label: L10N.get('Common.close'),
					action: () => {
						infoPopup.hide();
					},
				}],
			});
			infoPopup.show();
		}
	},

	sendToAuthorization(e) {
		if (e) e.preventDefault();
		this.disableElements(e);
		STATE.showLoader();
		const reservations = [];
		
		_.each(this.selectedReservation, (el) => {
			if (el && (el.get('selected').toString() === 'true' && el.get('needAuthorization').toString() === 'true')) {
				reservations.push(el.get('uid'));
			}
		});
		axios.post('/midoffice/ibecorp-b2b/booking/sendToAuthorization', {
			parameters: {
				booking: {
					uid: this.model.get(`checkBooking`).bookingInfo.bookingUid,
				},
				reservations,
			},
		})
			.then(() => window.location.reload())
			.catch((err) => {
				STATE.hideLoader();
				if (axios.isCancel(err)) {
					logger.error(err);
				} else {
					throw new Error(err);
				}
			});
	},

	setVisiblePrice() {
		if (this.model.get('initiatePayment') != null && this.model.get('initiatePayment').get('paymentType') != null) {
			const value = this.model.get('initiatePayment').get('paymentType');
			const paymentType = _.find(this.model.get('checkBooking').paymentInfo, (el) => el[value] != null && el[value].price != null);
			this.model.get('initiatePayment').set('visiblePrice', !_.isEmpty(paymentType) ? paymentType[value].price.amount : null);

			if (this.model.get('initiatePayment').get('visiblePrice') != null && this.selectedIssueAllowed && !this.selectedAuthorizationNeeded &&
				!this.selectedAuthorizationRefused && !this.options.order.allReservationHasErrors && !this.selectedApprovalNeeded) {
				this.$el.find('.b-order__payment .b-order__payment-hidden').show();
			} else {
				this.$el.find('.b-order__payment .b-order__payment-hidden').hide();
			}
			if (this.model.get('initiatePayment').get('visiblePrice') != null && this.selectedApprovalNeeded && !this.selectedAuthorizationNeeded &&
				!this.selectedAuthorizationRefused && !this.options.order.allReservationHasErrors && !this.selectedIssueAllowed) {
				this.$el.find('.b-order__payment .b-payment__approval').show();
			} else {
				this.$el.find('.b-order__payment .b-payment__approval').hide();
			}
		}

		if (this.shouldShowAuthBtn(this.options.order)) {
			this.$el.find('.b-order__payment .b-payment__authorization').show();
		} else {
			this.$el.find('.b-order__payment .b-payment__authorization').hide();
		}

		// UTS-634 - если одновременно выбрали "согласование" и "авторизация"
		if (this.selectedApprovalNeeded && this.selectedAuthorizationNeeded) {
			this.$el.find('.b-order__payment .b-payment__authorization').hide();
			this.$el.find('.b-order__payment .b-payment__approval').hide();
		}
	},

	async renderPaymentView() {
		this.collectSelectedReservationsState();
		const {order} = this.options;
		const selectedReservation = this.model.get('selectedReservation') || {};
		const bookingUid = this.options.booking.bookingInfo.bookingUid;
		const params = {
			bookingToCheck: bookingUid,
			reservationsToCheck: [],
		};
		if (!_.isEmpty(selectedReservation)) {
			Object.keys(selectedReservation).forEach(k => {
				if (selectedReservation[k].get('selected')) {
					if (order.reservations[k].union) {
						order.reservations[k].prn.forEach(pnr => {
							params.reservationsToCheck.push(pnr.uid);
						});
					} else {
						params.reservationsToCheck.push(order.reservations[k].uid);
					}
				}
			});
		}
		this.model.get('initiatePayment').set('reservationsToPay', params.reservationsToCheck);

		const hasPaymentInHotel = this.renderPaymentInHotel();

		const hidePaymentBlock = () => {
			if (this.paymentView != null) {
				this.paymentView.remove();
			}
			this.$el.find('.b-order__payment-container, .b-order__price-container').empty();
		};

		if (!params.reservationsToCheck || params.reservationsToCheck.length === 0) {
			hidePaymentBlock();
			return;
		}

		const loaderTimerId = setTimeout(() => {
			STATE.showLoader();
		}, 500);
		const paymentInfo = await axios.post('/midoffice/ibecorp-b2b/booking/getPaymentTypes', {
			parameters: params,
		}).then(response => response.data.result.paymentInfo).catch((err) => {
			STATE.hideLoader();
			if (axios.isCancel(err)) {
				logger.error(err);
			} else {
				throw new Error(err);
			}
		});
		clearTimeout(loaderTimerId);
		STATE.hideLoader();

		this.model.get('checkBooking').paymentInfo = paymentInfo;

		if (!this.selectedAuthorizationNeeded && !order.allReservationHasErrors && !this.selectedAuthorizationRefused) {
			this.$el.find('.b-payment__pay').show();
		}

		if (hasPaymentInHotel) {
			this.$el.find('.b-payment__pay').html(L10N.get('cabinet.orders.doBooking'));
			this.$el.find('.b-payment__pay').removeAttr('disabled').removeClass('btn-disabled');
		} else {
			this.$el.find('.b-payment__pay').addClass('btn-disabled').attr('disabled');
		}

		if (this.checkPriceBlank(paymentInfo)) {
			_.defer(() => {
				this.$el.find('.b-order__price-container').empty();
				this.$el.find('.b-order__payment-container').hide();
				this.renderPricingInfo();
			});
		}
		this.paymentView = new PaymentView({
			model: this.model,
			checkBooking: {
				paymentInfo,
			},
			hasPaymentInHotel,
		});

		this.$el.find('.b-order__payment-container').html(this.paymentView.$el);
		_.defer(() => {
			this.$el.find('.b-order__payment-container .b-payment__item:first-child input[type="radio"]').trigger('click');
			// check selected payment and set visible price to model after payment types updated
			this.setVisiblePrice();

			// if payment type selected then enable button
			const selectedPaymentType = this.paymentView.model.get(`initiatePayment`).get(`paymentType`);
			if (selectedPaymentType) {
				this.$el.find('.b-payment__pay').removeClass('btn-disabled').removeAttr('disabled');
			} else {
				this.$el.find('.b-payment__pay').addClass('btn-disabled').attr('disabled');
			}

			// IBECORP-3389
			// Payment and approval buttons are showing/hiding in this file only (not in template)
			// The last async action here!
				
			if (this.isAllIntentions && !order.needAuthorization && !order.allReservationHasErrors && !order.authorizationRefused) {
				this.$el.find('.b-order__payment .b-order__payment-hidden').hide();
				this.$el.find('.b-order__payment .b-payment__approval').show();
				if (STATE.checkViewport('(max-width: 768px)')) this.adjustMobileFooterBtns();
			}
		});
		if (STATE.checkViewport('(max-width: 768px)')) this.adjustMobileFooterBtns();
		this.renderPricingInfo(); 
	},

	checkPriceBlank(paymentInfo) {
		const checkPriceBlank = (obj) => {
			if (!_.isArray(obj) && !_.isObject(obj)) {
				return false;
			}

			const hasBlankPrice = _.some(obj, (val, k) => {
				return (
					(val && k === 'price' && parseFloat(val.amount) === 0) ||
					(val && val.price && parseFloat(val.price.amount) === 0)
				);
			});
			if (hasBlankPrice) {
				return true;
			}

			return _.some(obj, (val) => checkPriceBlank(val));
		};

		return checkPriceBlank(paymentInfo);
	},

	renderPricingInfo() {
		const {order} = this.options;

		const pricingContainer = this.$el.find('.b-order__price-container');
		const paymentType = this.$('[name="initiatePayment.paymentType"]:checked').attr('value');
		const paymentInfo = _.find(this.model.get('checkBooking').paymentInfo, (el) => el[paymentType] != null);

		let commission = null;
		if (paymentInfo != null) {
			commission = paymentInfo[paymentType].commission;
		}

		const selectedReservations = _.filter(order.reservations, (r) => this.selectedReservation[r._index] != null &&
			this.selectedReservation[r._index].get('selected').toString() === 'true');

		/* Price container */
		pricingContainer.html(new PricingView({
			reservations: selectedReservations,
			commission,
			isSabreProvider: this.isSabreProvider,
		}).$el);

		return this;
	},

	renderPaymentInHotel() {
		const {order} = this.options;
		const selectedReservations = _.filter(order.reservations, (r) => this.selectedReservation[r._index] != null &&
			this.selectedReservation[r._index].get('selected').toString() === 'true');
		const paymentInHotelContainer = this.$el.find('.b-order__payment-in-hotel-container');
		if (!selectedReservations) return false;

		const hotels = _.filter(selectedReservations, (r) => (
			r && r.travelSubject && r.travelSubject.uid === 'HOTEL_RESERVATION' &&
			_.some(r.services, (s) => s && s.offer && s.offer.paymentInHotel === true,
			)
		));

		this.isSabreProvider = _.some(_.flatten(_.map(hotels, (res) => res.services)), (el) => {
			return el && el.offer && el.offer.provider && el.offer.provider.uid === 'SABRE';
		});

		const price = _.reduce(hotels, (prev, cur) => {
			if (_.isEmpty(prev)) {
				prev = cur && cur.price ? _.extend({}, cur.price.total) : prev;
				prev.amount = parseFloat(prev.amount);
			} else if (prev.amount && cur && cur.price && cur.price.total) {
				prev.amount += parseFloat(cur.price.total.amount);
			}
			return prev;
		}, {});

		if (hotels && !_.isEmpty(price) && !this.isSabreProvider) {
			paymentInHotelContainer.html(paymentInHotelTemplate({price}));
			return true;
		}

		return false;
	},

	collectSelectedReservationsState() {
		this.selectedApprovalNeeded = [];
		this.selectedIssueAllowed = [];
		this.selectedAuthorizationNeeded = [];
		this.selectedAuthorizationRefused = [];
		_.each(this.selectedReservation, (el) => {
			if (!el || el.get('selected').toString() !== 'true') return;
			// IBECORP-7109: убираем ради кнопки "Отправить на согласование" и ждем итогового тестирования
			// if (el.get('status') === 'PROPOSAL' && el.get('travelSubject') === 'HOTEL_RESERVATION') return;

			if (el.get('approvalNeeded').toString() === 'true') this.selectedApprovalNeeded.push(true);
			if (el.get('issueAllowed').toString() === 'true') this.selectedIssueAllowed.push(true);
			if (el.get('needAuthorization').toString() === 'true') this.selectedAuthorizationNeeded.push(true);
			if (el.get('authorizationRefused').toString() === 'true') this.selectedAuthorizationRefused.push(true);
		});
		this.selectedApprovalNeeded = this.selectedApprovalNeeded.length > 0;
		this.selectedIssueAllowed = this.selectedIssueAllowed.length > 0;
		this.selectedAuthorizationNeeded = this.selectedAuthorizationNeeded.length > 0;
		this.selectedAuthorizationRefused = this.selectedAuthorizationRefused.length > 0;
	},

	async toggleSelectAllReservations() {
		const {order} = this.options;

		await this.renderPaymentView();
		_.each(order.reservations, (reservation, index) => {
			if (reservation.issueAllowed) {
				this.__toggleSelectReservations(index, true, true);
			}
		});
	},

	toggleSelectReservations(index) {
		this.__toggleSelectReservations(index);
	},

	shouldShowAuthBtn(order, silent = false) {
		if (silent) {
			const approvalButtonAlreadyShowed = this.shouldShowApprovalNeeded(order, true);
			if (approvalButtonAlreadyShowed) {
				return false;
			}
		}

		return this.selectedAuthorizationNeeded && !this.selectedAuthorizationRefused;
	},

	shouldShowApprovalNeeded(order, silent = false) {
		if (silent) {
			const authButtonAlreadyShowed = this.shouldShowAuthBtn(order, true);
			if (authButtonAlreadyShowed) {
				return false;
			}
		}

		return !this.selectedAuthorizationNeeded 
			&& !order.allReservationHasErrors && !this.selectedAuthorizationRefused;
	},

	async __toggleSelectReservations(index, selectedState, silent) {
		const {order} = this.options;
		const sellReservations = _.filter(order.reservations, (reservation) => reservation.issueAllowed);

		if (!silent) {
			await this.renderPaymentView();
		} else {
			this.collectSelectedReservationsState();
		}

		if (_.size(sellReservations) > 1) {
			const $reservationContainer = this.$el.find('.b-order-reservation').filter(`[data-reservation-id="${index}"]`);
			if (selectedState != null) {
				if (selectedState) {
					$reservationContainer.addClass('is-selected');
				} else {
					$reservationContainer.removeClass('is-selected');
				}
			} else {
				$reservationContainer.toggleClass('is-selected');
			}
		}

		const $paymentPrnContainer = this.$el.find('.b-order__payment-reservations-prn').empty();

		if (!this.selectedIssueAllowed) {
			this.$el.find('.b-order__payment .b-order__payment-hidden').hide();
			$paymentPrnContainer.empty();
		} else if (!this.selectedAuthorizationNeeded && !order.allReservationHasErrors && !this.selectedAuthorizationRefused) {
			this.$el.find('.b-order__payment .b-order__payment-hidden').show();
		}

		if (!this.selectedApprovalNeeded) {
			this.$el.find('.b-order__payment .b-payment__approval').hide();
		} else if (this.shouldShowApprovalNeeded(order)) {
			this.$el.find('.b-order__payment .b-payment__approval').show();
		}

		if (this.shouldShowAuthBtn(order)) {
			$paymentPrnContainer.empty();
			this.$el.find('.b-order__payment .b-payment__authorization').show();
		} else {
			this.$el.find('.b-order__payment .b-payment__authorization').hide();
		}

		const selectedReservations = _.filter(sellReservations, (r) => {
			return this.selectedReservation[r._index] != null && this.selectedReservation[r._index].get('selected').toString() === 'true';
		});
		const groupedReservations = _.groupBy(selectedReservations, (r) => r && r.travelSubject && r.travelSubject.uid);

		_.each(groupedReservations, (reservations, type) => {
			$paymentPrnContainer.append(`<i class="g-icon-service g-icon-service--color-black
				g-icon-service--type-${type.toLowerCase()}"></i>`);

			_.each(reservations, (r) => {
				const prn = _.isArray(r.prn) ? _.map(r.prn, (item) => item.prn).join(', ') : r.prn;

				const $element = $(`<span>№${prn}</span>`);
				$element.addClass('b-order__payment-reservation-cancel');
				$element.attr('data-reservation-id', r._index);

				if (_.size(sellReservations) > 1) {
					$element.addClass('is-deletable');
					$element.click(() => {
						this.selectedReservation[r._index].set('selected', false);
						this.model.trigger(`change:selectedReservation.${r._index}.selected`, null, false);
					});
				}

				$paymentPrnContainer.append($element);
			});
		});
	},

	toggleSelectService(index, e) {
		const model = this.selectedServices[index];
		const services = _.omit(model.attributes, 'allServices');
		const {reservations} = this.options.order;
		const $reservationContainer = this.$el.find('.b-order-reservation').filter(`[data-reservation-id="${index}"]`);
		if (!_.isEmpty(e.changed) && e.changed.allServices != null) {
			const allSelected = (e.changed.allServices === true);

			_.each(services, (value, key) => {
				const selected = (value.get('disabled') !== true) && allSelected;

				model.set(`${key}.selected`, selected);
				this.model.trigger(`change:selectedServices.${index}.${key}.selected`, value, selected);
			});
		} else {
			const allSelected = _.every(services, (el) => el.get('selected') === true);

			model.set('allServices', allSelected, {silent: true});
			this.model.trigger(`change:selectedServices.${index}.allServices`, model, allSelected);
		}

		const selectedServices = _.filter(reservations[index].services, (service) => services[service.uid] != null &&
			services[service.uid].get('selected') === true);

		const refundDisabled = !_.every(selectedServices, (service) => service.refundable === true);
		const changeEregDisabled = !_.every(selectedServices, (service) => service.changeERegAvailable === true);
		const voucherPrintedEnabled = _.every(selectedServices, (service) => service.voucherPrinted === true);

		const allSelected = _.every(services, (el) => el.get('selected') === true);
		
		/*
			IBECORP-6544: с точки зрения бизнес логики, в одном reservation должны быть сервисы одного типа
			(пример, в reservation по ЖД - только ЖД). Но технически может получиться, что в одну бронь поместят
			билеты разных типов. Т.к. тип есть только у reservation, мы не можем проверить, к какому типу по факту
			принадлежат имеющиеся в нем services.
		*/

		const isRailwayReservation = (reservations[index] && reservations[index]?.travelSubject?.uid) || false;

		if (refundDisabled === true || _.size(selectedServices) === 0 || (!isRailwayReservation && !allSelected)) {
			$reservationContainer.find('.b-refund-services').addClass('btn-disabled');
			$reservationContainer.find('[data-target-class=".b-refund-services"]').addClass('disabled');
		} else {
			$reservationContainer.find('.b-refund-services').removeClass('btn-disabled');
			$reservationContainer.find('[data-target-class=".b-refund-services"]').removeClass('disabled');
		}

		if (changeEregDisabled === true || _.size(selectedServices) === 0 || (!isRailwayReservation && !allSelected)) {
			$reservationContainer.find('.b-change-ereg-send, .b-change-ereg-print').addClass('btn-disabled disabled');
			$reservationContainer
				.find('[data-target-class=".b-change-ereg-send"], [data-target-class=".b-change-ereg-print"]').addClass('disabled');
		} else {
			$reservationContainer.find('.b-change-ereg-send, .b-change-ereg-print').removeClass('btn-disabled disabled');
			$reservationContainer
				.find('[data-target-class=".b-change-ereg-send"], [data-target-class=".b-change-ereg-print"]').removeClass('disabled');
		}

		// IBECORP-3413
		if (voucherPrintedEnabled === true && _.size(selectedServices) !== 0) {
			$reservationContainer.find('.b-order__voucher-message').addClass('b-order__voucher-message--shown');
		} else {
			$reservationContainer.find('.b-order__voucher-message').removeClass('b-order__voucher-message--shown');
		}
	},

	// flight receipts checkbox handler
	// ----------------------------
	toggleSelectServiceAvia(index, e) {
		const model = this.selectedServicesAvia[index];
		const services = _.omit(model.attributes, 'allServices');

		const {reservations} = this.options.order;
		const $reservationContainer = this.$el.find('.b-order-reservation').filter(`[data-reservation-id="${index}"]`);

		if (!_.isEmpty(e.changed) && e.changed.allServices != null) {
			const allSelected = (e.changed.allServices === true);

			_.each(services, (value, key) => {
				const selected = (value.get('disabled') !== true) && allSelected;

				model.set(`${key}.selected`, selected);
				this.model.trigger(`change:selectedServicesAvia.${index}.${key}.selected`, value, selected);
			});
		} else {
			const allSelected = _.every(services, (el) => el.get('selected') === true);

			model.set('allServices', allSelected, {silent: true});
			this.model.trigger(`change:selectedServicesAvia.${index}.allServices`, model, allSelected);
		}

		const selectedServices = _.filter(reservations[index].services, (service) => services[service.uid] != null &&
			services[service.uid].get('selected') === true);

		const flightReceiptDisabled = !_.some(selectedServices, (service) => service.certificateOfCompletedFlightCreationPossible === true);
		// set checked lines
		this.model.set('selectedServicesForFlightReceipts', selectedServices); // flight receipts, selectedServices - data of checked line (service field)

		const allSelected = _.every(services, (el) => el.get('selected') === true);
		if (reservations[index].isVoidBookingAllowed === false || _.size(selectedServices) === 0 || !allSelected) {
			$reservationContainer.find('.b-payment__void').addClass('btn-disabled');
			$reservationContainer.find('[data-target-class=".b-payment__void"]').addClass('disabled');
		} else {
			$reservationContainer.find('.b-payment__void').removeClass('btn-disabled');
			$reservationContainer.find('[data-target-class=".b-payment__void"]').removeClass('disabled');
		}

		if (_.size(selectedServices) > 0 && _.every(selectedServices, p => p.refundable === true) && allSelected) {
			$reservationContainer.find('.b-refund-services').removeClass('btn-disabled');
			$reservationContainer.find('[data-target-class=".b-refund-services"]').removeClass('disabled');
		} else {
			$reservationContainer.find('.b-refund-services').addClass('btn-disabled');
			$reservationContainer.find('[data-target-class=".b-refund-services"]').addClass('disabled');
		}

		if (_.size(selectedServices) > 0 && _.every(selectedServices, p => p.exchangeable === true) && allSelected) {
			$reservationContainer.find('.b-exchange-services').removeClass('btn-disabled');
			$reservationContainer.find('[data-target-class=".b-exchange-services"]').removeClass('disabled');
		} else {
			$reservationContainer.find('.b-exchange-services').addClass('btn-disabled');
			$reservationContainer.find('[data-target-class=".b-exchange-services"]').addClass('disabled');
		}

		if (flightReceiptDisabled === true || _.size(selectedServices) === 0 || !allSelected) {
			$reservationContainer.find('.b-get-flight-receipt').addClass('btn-disabled disabled');
			$reservationContainer.find('[data-target-class=".b-get-flight-receipt"]').addClass('disabled');
		} else {
			$reservationContainer.find('.b-get-flight-receipt').removeClass('btn-disabled disabled');
			$reservationContainer.find('[data-target-class=".b-get-flight-receipt"]').removeClass('disabled');
		}
	},

	groupReservation(reservation = {}) {
		// Merge AIR legs
		if (reservation.travelSubject.uid === 'AIR') {
			_.each(reservation.legs, (leg, iLeg) => {
				if (leg !== undefined && leg?.segments !== undefined) { // quickfix for IBECORP-6957
					const sliced = reservation.legs.slice(iLeg + 1);
					_.each(sliced, (compareLeg, iCompareLeg) => {
						let equalLeg = false;

						if (compareLeg == null) {
							return;
						}

						if (_.size(leg.segments) === _.size(compareLeg.segments)) {
							_.each(leg.segments, (segment, iSegment) => {
								const compareSegment = compareLeg.segments[iSegment];
								if (segment.flightNumber === compareSegment.flightNumber
								&& segment.departureDate === compareSegment.departureDate
								&& segment.airline?.uid === compareSegment.airline?.uid) {
									equalLeg = true;
								}
							});
						}

						if (equalLeg === true) {
							delete reservation.legs[iLeg + 1 + iCompareLeg];
						}
					});
				}
			});
			reservation.legs = _.compact(reservation.legs);
		} else if (reservation.travelSubject.uid === 'RAILWAY') {
			_.each(reservation.carriages, (c, iC) => {
				if (c !== undefined && c?.segments !== undefined) { // quickfix for UTS-1092
					const sliced = reservation.carriages.slice(iC + 1);
					_.each(sliced, (compareC, iCompareC) => {
						let equalCar = false;

						if (compareC == null) {
							return;
						}

						if (_.isEqual(c.segment, compareC.segment) &&
							(c.carriageCardId === compareC.carriageCardId) &&
							(c.number === compareC.number)) {
							equalCar = true;
						}	

						if (equalCar === true) {
							c.places = _.uniq([...c.places, ...compareC.places]);
							delete reservation.carriages[iC + 1 + iCompareC];
						}
					});
				}
			});
			reservation.carriages = _.compact(reservation.carriages);
		}

		_.each(reservation.services, (service) => {
			// Merge mco
			if (service.mco != null) {
				const relatedServices = _.filter(reservation.services, (s) => service.mco.related.includes(s.uid));

				_.map(relatedServices, (s) => {
					if (s._mco == null) {
						s._mco = [];
					}

					if (_.every(s._mco, (el) => el.uid !== service.uid)) {
						s._mco.push(_.extend({}, _.pick(service.mco, 'category'), _.pick(service, ['ticketNumber', 'status', 'uid'])));
					}
				});
				if (!_.isEmpty(relatedServices)) {
					service._remove = true;
				}
				return;
			}

			// Merge flight receipt
			if ('additionalService' in service &&
				'category' in service.additionalService &&
				service.additionalService.category.uid === 'CERTIFICATE_OF_COMPLETED_FLIGHT') {
				// find services which have certeficates
				const relatedServices = [];

				for (let i = 0; i < service.additionalService.related.length; i++) {
					const search = reservation.services.find(({uid}) => {
						return uid === service.additionalService.related[i];
					});

					if (search) {
						relatedServices.push(search);
					}
				}

				_.map(relatedServices, (s) => {
					if (s._receipts == null) {
						s._receipts = [];
					}

					if (_.every(s._receipts, (el) => el.uid !== service.uid)) {
						s._receipts.push(_.extend({}, _.pick(service.additionalService, 'category'), _.pick(service, ['ticketNumber', 'status', 'uid'])));
					}
				});
				if (!_.isEmpty(relatedServices)) { // What is it?
					service._remove = true;
				}
			}
		});
		reservation.services = _.filter(reservation.services, (service) => service._remove !== true);

		return reservation;
	},

	groupServices(reservations = []) {
		_.map(reservations, this.groupReservation.bind(this));

		return _.filter(reservations, (reservation) => {
			let accepted = true;

			if (reservation.union === true) {
				return accepted;
			}

			if (reservation.travelSubject.uid === 'RAILWAY') {
				if (!reservation.prn) return accepted;
				const prn = reservation.prn.split('/')[0];
				const backReservation = _.find(reservations, (el) => {
					return (el && el.prn) && el.prn !== reservation.prn && el.prn.indexOf(prn) !== -1;
				});

				if (backReservation != null) {
					const getSegmentInfo = (r) => _.pick((r.carriages[0] || {}).segment, 'arrivalLocation', 'departureLocation');

					if (_.isArray(backReservation.prn)) {
						backReservation.prn.push({
							uid: reservation.uid,
							prn: reservation.prn,
							segment: getSegmentInfo(reservation),
						});
					} else {
						backReservation.prn = [{
							uid: reservation.uid,
							prn: reservation.prn,
							price: reservation.price,
							segment: getSegmentInfo(reservation),
						}, {
							uid: backReservation.uid,
							prn: backReservation.prn,
							price: backReservation.price,
							segment: getSegmentInfo(backReservation),
						}];
					}

					backReservation._price = [{...reservation.price}, {...backReservation.price}];
					backReservation.price = PricingView.deepMergeSum(reservation.price, backReservation.price);
					backReservation.services = [...reservation.services, ...backReservation.services];
					backReservation.carriages = [...reservation.carriages, ...backReservation.carriages];
					backReservation.statuses = [_.map([reservation.status, backReservation.status], (s) => s && s.uid)];

					backReservation.union = true;

					accepted = false;
				}
			}

			return accepted;
		});
	},

	bindWidgets(...args) {
		BaseView.prototype.bindWidgets.apply(this, args);

		const formatPassengers = (order) => {
			let uniqTravellers = [];
			const result = {
				childsAge: [],
				travellersInfo: {},
			};
			_.each(order.reservations, (reservation) => {
				uniqTravellers = _.uniq(_.reduce(reservation.services, (a, b) => a.concat(b.travellers), []));
			});
			_.each(uniqTravellers, ({travellerType, passportInfo}) => {
				if (!travellerType) return null;
				if (result.travellersInfo[travellerType.uid]) {
					result.travellersInfo[travellerType.uid]++;
				} else {
					result.travellersInfo[travellerType.uid] = 1;
				}
				if (travellerType.uid === 'CHILD') result.childsAge.push(new Time().moment.diff(passportInfo.birthday, 'years'));

				return result;
			});
			if (!result.childsAge.length) delete result.childsAge;
			return result;
		};

		const {order, booking} = this.options;
		const $additionalServices = this.$el.find('.b-order__additional-select select');
		const numberOfOptions = $additionalServices.children('option').length;

		$additionalServices.addClass('select-hidden');
		$additionalServices.wrap('<div class="select"></div>');
		$additionalServices.after('<div class="select-styled"></div>');

		const $styledSelect = $additionalServices.next('div.select-styled');
		$styledSelect.text($additionalServices.children('option').eq(0).text());

		const $list = $('<ul />', {
			class: 'select-options',
		}).insertAfter($styledSelect);

		for (let i = 0; i < numberOfOptions; i++) {
			const value = $additionalServices.children('option').eq(i).val();
			const text = $additionalServices.children('option').eq(i).text();

			$('<li />', {
				html: `<i class="g-icon-service g-icon-service--color-black g-icon-service--type-${value.toLowerCase()}"></i> ${text}`,
				rel: value,
			}).appendTo($list);
		}

		const $listItems = $list.children('li');

		$styledSelect.click(function click(e) {
			if (e != null) {
				e.stopPropagation();
			}

			$('div.select-styled.active').not(this).each(function each() {
				$(this)
					.removeClass('active')
					.next('ul.select-options')
					.hide();
			});
			$(this)
				.toggleClass('active')
				.next('ul.select-options')
				.toggle();
		});

		$listItems.click(function click(e) {
			if (e != null) {
				e.stopPropagation();
			}
			const _this = this;

			if (order.subagencyDoesntMatch) {
				const popup = new Widgets.Popup({
					content: L10N.get('bookingForm.subAgencyDoesntMatch'),
					type: 'info',
					actions: [
						{
							label: L10N.get('bookingForm.back'),
							action: () => {
								popup.hide();
							},
						},
						{
							label: L10N.get('bookingForm.continue'),
							action: () => {
								Backbone.history.navigate('', {
									replace: true,
								});

								STATE.router.search({
									travelSubject: $(_this).attr('rel'),
									booking: {
										number: order.number,
										bookingUid: booking.bookingInfo.bookingUid,
									},
								});
								popup.hide();
							},
						},
					],
					onClose: () => {
						popup.hide();
					},
				});
				popup.show();
				return this;
			}

			Backbone.history.navigate('', {
				replace: true,
			});

			STATE.router.search({
				travelSubject: $(_this).attr('rel'),
				passengersFromOrder: formatPassengers(order),
				booking: {
					number: order.number,
					bookingUid: booking.bookingInfo.bookingUid,
				},
			});
			return this;
		});

		$(document).click(() => {
			$styledSelect.removeClass('active');
			$list.hide();
		});
		return this;
	},

	// EVENTS
	resize() {
		const $routeLocations = this.$el.find('.b-order-label__route');
		const $createDate = this.$el.find('.b-order-label__create-date');

		if ((window.innerWidth || screen.width) < 1440 && !STATE.checkViewport('(max-width: 768px)')) {
			_.each($routeLocations, (el) => {
				const $el = $(el);

				if ($el.outerWidth(true) >= 450) {
					$el.hide();
					$el.next('.b-order-label__create-date').tooltip({
						container: 'body',
						title: $el.text(),
					});
				} else {
					$el.show();
					$el.next('.b-order-label__create-date').tooltip('dispose');
				}
			});
		} else {
			$routeLocations.show();
			$createDate.tooltip('dispose');
		}

		this.syncTravelDocumentWidgetWidthPosition();
	},

	expandContainer(e) {
		if (e != null) {
			e.preventDefault();
		}

		const $target = $(e.target);
		const reservation = $target.closest('.b-order-reservation');

		if (reservation.hasClass('is-open')) {
			reservation
				.find('.b-order__container-expand')
				.stop()
				.slideUp(400, () => {
					reservation.removeClass('is-open');
				});
		} else {
			reservation
				.addClass('is-open')
				.find('.b-order__container-expand')
				.stop()
				.slideDown();
		}
	},

	closeModalWindow() {
		$('.b-popup-mask').hide();
	},

	reloadOnErrorPopupListener() {
		setTimeout(() => {
			$('.b-popup__action[data-id="0"]').on('click', () => window.location.reload());
		}, 100);
	},

	openRefundExchangeModal(params) {
		const {selectedServices, reservationIndex} = params;
		const refundExchangeModalServices = {
			[reservationIndex]: {},
		};
		_.each(_.omit(selectedServices[reservationIndex].attributes, 'allServices'), (model, key) => {
			refundExchangeModalServices[reservationIndex][key] = new BaseModel({
				selected: model.get('selected'), disabled: model.get('disabled'),
			});
		});

		params = _.omit(params, ['reservationIndex']);
		this.refundExchangeModal = new RefundExchangeModalView(_.extend(params, {
			parent: this,
			selectedServices: refundExchangeModalServices,
		}));
	},

	buildExchangeRouteParameter(reservation) {
		return _.map(reservation.legs, (l) => {
			const first = _.first(l.segments);
			const last = _.last(l.segments);
			return {
				date: first.departureDate.split('T')[0],
				departureCode: first.departureCity.uid,
				departure: first.departureCity,
				arrivalCode: last.arrivalCity.uid,
				arrival: last.arrivalCity,
				changed: true,
			};
		});
	},

	exchangeServices(e) {
		const {order, booking} = this.options;

		const $target = $(e.target);
		const reservationIndex = $target.closest('.b-order-reservation').attr('data-reservation-id');

		const reservation = order.reservations[reservationIndex];
		const selectedServices = this[reservation.travelSubject.uid === 'AIR' ? 'selectedServicesAvia' : 'selectedServices'];
		const productUids = this.getProductUidsFromSelectedServices(selectedServices, reservation);

		const refundExchangeModalOptions = {
			productUids,
			reservation,
			reservationIndex,
			selectedServices,
			booking,
			isExchange: true,
			refund: null,
		};
		const parameters = {
			parameters: {
				booking: {
					uid: booking.bookingInfo.bookingUid,
				},
				productUids,
				route: this.buildExchangeRouteParameter(reservation),
			},
		};

		if (reservation.travelSubject.uid === 'AIR') {
			return axios.post('/midoffice/ibecorp-b2b/booking/exchange/flights/validate', parameters).then(() => {
				this.openRefundExchangeModal(refundExchangeModalOptions);
			}).catch(err => {
				const {validatedParameters} = err || {};
				if (validatedParameters) {
					const tempModel = new BaseModel(parameters.parameters);
					const messages = _.uniq(_.flatten(tempModel.handleValidation(validatedParameters)), ['code']);
					const popup = new Widgets.Popup({
						content: _.map(messages, (m) => m.text).join('</br>'),
						closeOnlyOnAction: true,
						type: 'danger',
						actions: [{
							label: 'Закрыть',
							action() {
								popup.hide();
							},
						}],
					});
					popup.show();
				}
			});
		}

		return this.openRefundExchangeModal(refundExchangeModalOptions);
	},

	getProductUidsFromSelectedServices(selectedServices, reservation) {
		const productUids = [];
		if (selectedServices[reservation._index] != null) {
			const servicesCollection = _.omit(selectedServices[reservation._index].attributes, 'allServices');
			_.each(servicesCollection, (model, key) => {
				if (model.get('selected') === true) {
					const service = _.find(reservation.services, (s) => s.uid === key && s.aclViewGranted);
					if (service) {
						productUids.push(key);
						if (!_.isEmpty(service._mco)) productUids.push(..._.map(service._mco, (s) => s.uid));
					}
				}
			});
		} else {
			productUids.push(..._.map(reservation.services, (service) => service.aclViewGranted && service.uid));
		}

		return productUids;
	},

	refundServices(e) {
		const {order} = this.options;

		const $target = $(e.target);
		const reservationIndex = $target.closest('.b-order-reservation').attr('data-reservation-id');

		const {booking} = this.options;
		const reservation = order.reservations[reservationIndex];
		const selectedServices = this[reservation.travelSubject.uid === 'AIR' ? 'selectedServicesAvia' : 'selectedServices'];
		const productUids = this.getProductUidsFromSelectedServices(selectedServices, reservation);

		const params = {
			parameters: {
				booking: {
					uid: booking.bookingInfo.bookingUid,
				},
				productUids,
			},
		};

		if (['AIR', 'HOTEL_RESERVATION'].includes(reservation.travelSubject.uid)) {
			STATE.showLoader();
			this.disableElements(e);
			axios.post('/midoffice/ibecorp-b2b/booking/refundDetails', params, this.model).then((res) => {
				STATE.hideLoader();
				if (reservation.travelSubject.uid === 'AIR') {
					if (res.data.result) {
						const refundExchangeModalOptions = {
							productUids,
							reservation,
							reservationIndex,
							selectedServices,
							booking,
							refund: res.data.result,
						};
						this.openRefundExchangeModal(refundExchangeModalOptions);
					}
				}
				if (reservation.travelSubject.uid === 'HOTEL_RESERVATION') {
					const {refundPrice, financeDetailsToken} = res.data.result || {};
					const message = _.template(`${L10N.get('cabinet.orders.totalToRefund')}:
							<strong><%= Formatter.formatMoney(refundPrice.total.amount, Formatter.getDecimal(refundPrice.total.amount)) %>
							<%= Formatter.formatCurrency(refundPrice.total.currencyCode) %></strong>`);
					const popup = new Widgets.Popup({
						content: message({refundPrice, Formatter}),
						title: L10N.get('cabinet.orders.ticketRefund'),
						type: 'info',
						actions: [
							{
								label: L10N.get('cabinet.orders.back'),
								action: () => {
									popup.hide();
								},
							},
							{
								label: L10N.get('cabinet.orders.confirm'),
								action: () => {
									STATE.showLoader();
									params.parameters.financeDetailsToken = financeDetailsToken;
									axios.post('/midoffice/ibecorp-b2b/booking/refund', params).then(() => {
										STATE.hideLoader();
										popup.hide();
										STATE.router.reloadPage();
									});
								},
							},
						],
					});
					popup.show();
				}
			}).catch(() => this.reloadOnErrorPopupListener());

			return;
		}

		STATE.showLoader();
		this.disableElements(e);
		if (reservation.travelSubject.uid !== 'RAILWAY') {
			axios.post('/midoffice/ibecorp-b2b/booking/refund', params, this.model).then(() => {
				STATE.hideLoader();
				STATE.router.reloadPage();
			}).catch(() => this.reloadOnErrorPopupListener());
		} else {
			axios.post('/midoffice/ibecorp-b2b/booking/refund/railPreview', params).then((response) => {
				STATE.hideLoader();

				const {result} = response.data;
				new PdfViewerModal({
					data: result,
					label: L10N.get('cabinet.orders.refundTitle'),
					printCallback: () => {
						const {totalRefund, currencyCode} = result;
						const message = _.template(`${L10N.get('cabinet.orders.totalToRefund')}:
							<strong><%= totalRefund %> <%= Formatter.formatCurrency(currencyCode) %></strong>`);

						const popup = new Widgets.Popup({
							content: message({totalRefund, currencyCode, Formatter}),
							title: L10N.get('cabinet.orders.ticketRefund'),
							type: 'info',
							actions: [
								{
									label: L10N.get('cabinet.orders.back'),
									action: () => {
										popup.hide();
									},
								},
								{
									label: L10N.get('cabinet.orders.confirm'),
									action: () => {
										STATE.showLoader();
										axios.post('/midoffice/ibecorp-b2b/booking/refund', params).then(() => {
											STATE.hideLoader();
											popup.hide();
											STATE.router.reloadPage();
										}).catch((err) => {
											STATE.hideLoader();
											if (axios.isCancel(err)) {
												logger.error(err);
											} else {
												throw new Error(err);
											}
										});
									},
								},
							],
						});
						popup.show();
					},
				});
			}).catch((err) => {
				if (err?.response?.data?.errorType === 'com.gridnine.xtrip.common.ibecorp.exception.TicketsAlreadyRefundedException') {
					this.reloadOnErrorPopupListener();
					return;
				}
				throw err;
			});
		}
	},

	changeEregPrint(e) {
		const $target = $(e.target);
		const reservationIndex = $target.closest('.b-order-reservation').attr('data-reservation-id');
		const reservation = this.options.order.reservations[reservationIndex];
		const productUids = [];
		const selectedServices = _.omit(this.selectedServices[reservationIndex].attributes, 'allServices');
		const {booking} = this.options;

		_.each(selectedServices, (model, key) => {
			if (model.get('selected') === true) {
				const service = _.find(reservation && reservation.services, (s) => s.uid === key && s.aclViewGranted);
				if (service) productUids.push(key);
			}
		});

		STATE.showLoader();
		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/booking/ereg/preview', {
			parameters: {
				booking: {
					uid: booking.bookingInfo.bookingUid,
				},
				productUids,
			},
		}).then((response) => {
			STATE.hideLoader();

			const {result} = response.data;
			new PdfViewerModal({
				data: {
					previewDocument: result,
				},
			});
		}).catch((err) => {
			STATE.hideLoader();
			if (axios.isCancel(err)) {
				logger.error(err);
			} else {
				throw new Error(err);
			}
		});
	},

	changeEregSend(e) {
		const $target = $(e.target);
		const reservationIndex = $target.closest('.b-order-reservation').attr('data-reservation-id');
		const reservation = this.options.order.reservations[reservationIndex];

		const productUids = [];
		const selectedServices = _.omit(this.selectedServices[reservationIndex].attributes, 'allServices');
		const {booking} = this.options;

		_.each(selectedServices, (model, key) => {
			if (model.get('selected') === true) {
				const service = _.find(reservation && reservation.services, (s) => s.uid === key && s.aclViewGranted);
				if (service) productUids.push(key);
			}
		});

		STATE.showLoader();
		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/booking/ereg', {
			parameters: {
				booking: {
					uid: booking.bookingInfo.bookingUid,
				},
				productUids,
			},
		}).then(() => {
			STATE.hideLoader();
			STATE.router.reloadPage();
		}).catch((err) => {
			STATE.hideLoader();
			if (axios.isCancel(err)) {
				logger.error(err);
			} else {
				throw new Error(err);
			}
		});
	},

	saveNotes(e) {
		const notes = this.model.get('notes');
		const {booking} = this.options;

		STATE.showLoader();
		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/cabinet/editOrderComment', {
			parameters: {
				orderUid: booking.bookingInfo.bookingUid,
				notes,
			},
		}).then((response) => {
			STATE.hideLoader();
			if (response.statusText === 'Success') {
				const popup = new Widgets.Popup({
					type: 'info',
					content: `
						<div class="b-popup-successful__content">
							${L10N.get('cabinet.successfulSavedPopup.content')}
						</div>
					`,
					actions: [{
						label: L10N.get('cabinet.orders.close'),
						action: () => {
							popup.hide();
						},
					}],
				});
				popup.show();
			}
		}).catch((err) => {
			STATE.hideLoader();
			if (axios.isCancel(err)) {
				logger.error(err);
			} else {
				throw new Error(err);
			}
		});
	},

	showRouteReceipt(e) {
		const {reservations} = this.options.order;
		const $target = $(e.target);

		const reservationIndex = $target.closest('.b-order-reservation').attr('data-reservation-id');
		const reservation = reservations[reservationIndex];

		if (!reservation.services || (reservation.services.length === 0)) {
			const popup = new Widgets.Popup({
				type: 'danger',
				content: `
          <div class="b-popup-successful__content">
            ${L10N.get('cabinet.errors.missingServices')}
          </div>
        `,
				actions: [{
					label: L10N.get('cabinet.orders.close'),
					action: () => {
						popup.hide();
					},
				}],
			});
		} else {
			STATE.showLoader();
			this.disableElements(e);
			axios.get('/midoffice/ibecorp-b2b/cabinet/getItineraryReceipt', {
				params: {
					productUid: reservation.services[0].uid,
					useFeesAsTaxes: true,
					pnr: _.isArray(reservation.prn) ? reservation.prn[0].prn : reservation.prn,
					bookingUid: this.options.booking.bookingInfo.bookingUid,
				},
				responseType: 'arraybuffer',
			}, this.model).then((response) => {
				GlUl.downloadFile(response, 'itineraryReceipt', {type: 'application/pdf'});
			}).catch((err) => {
				STATE.hideLoader();
				if (axios.isCancel(err)) {
					logger.error(err);
				} else {
					throw new Error(err);
				}
			});
		}
	},

	async cancelBooking(e) {
		const {reservations} = this.options.order;
		const $target = $(e.target);

		const reservationIndex = $target.closest('.b-order-reservation').attr('data-reservation-id');
		const reservation = reservations[reservationIndex];
		let reservationsToCancel = [reservation.uid];

		if (_.isArray(reservation.prn)) {
			reservationsToCancel = _.compact(_.map(reservation.prn, (el) => el && el.uid));
		}

		STATE.showLoader();
		try {
			this.disableElements(e);
			const result = await axios.post('/midoffice/ibecorp-b2b/booking/cancelBooking', {
				parameters: {
					bookingToCancel: {
						uid: this.model.get('initiatePayment').get('bookingToPay'),
						caption: '',
					},
					reservationsToCancel,
				},
			});
			if (result && result.data && result.data.result && result.data.result.status) {
				location.reload(false);
			} else {
				throw new Error();
			}
		} catch (error) {
			Widgets.Popup.showUnknownErrorPopup();
		}

		STATE.hideLoader();
	},

	async voidBooking(e) {
		const {reservations} = this.options.order;
		const $target = $(e.target);

		const reservationIndex = $target.closest('.b-order-reservation').attr('data-reservation-id');
		const reservation = reservations[reservationIndex];

		const {booking} = this.options;
		const selectedServices = this[reservation.travelSubject.uid === 'AIR' ? 'selectedServicesAvia' : 'selectedServices'];
		const productUids = this.getProductUidsFromSelectedServices(selectedServices, reservation);

		const params = {
			parameters: {
				booking: {
					uid: booking.bookingInfo.bookingUid,
				},
				productUids,
			},
		};

		STATE.showLoader();
		try {
			this.disableElements(e);
			const result = await axios.post('/midoffice/ibecorp-b2b/booking/voidBooking', params);
			if (result && result.status.toString(10).startsWith('20')) {
				location.reload(false);
			} else {
				throw new Error();
			}
		} catch (error) {
			Widgets.Popup.showUnknownErrorPopup();
		}

		STATE.hideLoader();
	},

	changeHotelOffers(offers) {
		const hotelNames = [];

		_.each(offers, (offer) => {
			if (offer.travelSubject.uid === 'HOTEL_RESERVATION') {
				hotelNames.push(offer.card.name);
			}
		});

		const uniqHotels = [];

		for (const hotel of hotelNames) {
			if (!uniqHotels.includes(hotel)) {
				uniqHotels.push(hotel);
			}
		}

		_.each(uniqHotels, (hotelName, i) => {
			_.each(offers, (offer) => {
				if (offer.travelSubject.uid === 'HOTEL_RESERVATION' && offer.card.name === hotelName) {
					uniqHotels[i] = {
						hotel: uniqHotels[i].hotel ? uniqHotels[i].hotel : offer.card,
						offers: !uniqHotels[i].offers ? [offer] : [...uniqHotels[i].offers, offer],
						travelSubject: {
							uid: 'HOTEL_RESERVATION',
						},
					};
				}
			});
		});

		offers = offers.filter((offer) => offer.travelSubject.uid !== 'HOTEL_RESERVATION');
		offers = offers.concat(uniqHotels);
		return offers;
	},

	approvalBooking(e) {
		if (e != null) {
			e.preventDefault();
		}

		const selectedReservation = _.filter(this.selectedReservation, (el) => el.get('selected').toString() === 'true');
		const selectedUids = _.map(selectedReservation, (el) => el.get('uid'));

		const model = new BaseModel();

		if (!_.isEmpty(this.passengersToSend)) {
			this.passengers = _.map(this.passengersToSend, (p) => p && p.caption);
		}

		const passengers = this.passengers;
		const selectedOffers = _.filter(this.options.order.reservations, (r) => selectedUids.includes(r.uid));
		const offersToView = this.changeHotelOffers(selectedOffers);

		_.each(offersToView, (offer, i) => {
			switch (offer.travelSubject.uid) {
				case 'AIR': {
					offersToView[i].carrier = offer.legs[0].segments[0].airline;
					offersToView[i].servicesStatuses = {
						baggage: null,
						exchange: null,
						refund: null,
					};
					break;
				}
				case 'RAILWAY': {
					offersToView[i].train = offer.carriages[0].train;
					offersToView[i].carriage = offer.carriages[0];
					offersToView[i].seatsRequest = offer.seatsRequest;
					offersToView[i].train.number = offer.carriages[0].number;
					offersToView[i].train.category = offer.carriages[0].trainCategory;
					offersToView[i].train.brand = offer.carriages[0].trainBrand;
					offersToView[i].servicesStatuses = {
						baggage: null,
						exchange: null,
						refund: null,
					};
					break;
				}
			}
		});

		this.model.set('emails', new EmailsCollection([], {
			model: EmailModel,
		}));

		const autoSuggestEmails = this.model.get('autoSuggestEmails');
		const popup = new Widgets.Popup({
			content: approvalTemplate({offers: offersToView, passengers, autoSuggestEmails}),
			classes: 'b-popup__approval-booking b-ticket-popup-offers-modal',
			maskClasses: 'b-ticket-popup-offers-modal_mask b-order-popup-offers-modal_mask',
			actions: [
				{
					label: L10N.get('cabinet.orders.back'),
					classes: 'b-popup__approval-booking__action-back',
					action: () => {
						popup.hide();
					},
				},
				{
					label: L10N.get('cabinet.orders.send'),
					classes: 'b-popup__approval-booking__action-send',
					action: () => {
						this.disableElements(e);

						selectedUids.forEach((r, i) => {
							selectedUids[i] = {
								uid: selectedUids[i],
								comments: popup.model.get(`comments${r}`),
							};
						});
						const emails = this.model.get('emails').toJSON();

						axios.post('/midoffice/ibecorp-b2b/booking/sendToApprove', {
							parameters: _.extend({
								booking: {
									uid: this.model.get('initiatePayment').get('bookingToPay'),
									caption: '',
								},
								reservationWithComment: selectedUids,
								emails,
							}),
						}, model).then((result) => {
							popup.$el.find('.b-cabinet-order-approval-popup').css('visibility', 'hidden');
							const messages = result.data.messages;
							if (!_.isEmpty(messages)) {
								const messagePopup = new Widgets.Popup({
									type: 'info',
									content: messages.length && _.map(messages, (m) => (m.text || m)).join('<br/>'),
									actions: [
										{
											label: L10N.get('Common.close'),
											action: () => {
												messagePopup.hide();
											},
										},
									],
								});
								messagePopup.show();
							}
						}).catch((err) => {
							if (axios.isCancel(err)) {
								logger.error(err);
							} else {
								throw new Error(err);
							}
						}).finally(() => popup.hide());
					},
				},
			],
		});
		popup.applyBindingModel(model);
		popup.show();
		popup.$el.find('.b-offers-avia-modal__close').on('click', this.closeModalWindow);
		this.$el.find('.b-popup').css({width: '1100px'});

		const emailsContainer = popup.$('.b-offers-avia-modal__emails');
		emailsContainer.append(new EmailsCollectionView({
			autoSuggestEmails: this.model.get('autoSuggestEmails'),
			collection: this.model.get('emails'),
			container: emailsContainer,
			model: this.model,
		}));

		if (passengers != null) {
			popup.$el.find('.b-offers-avia-modal__title-person').html(passengers.join(', '));
			popup.$el.find('.b-offers-avia-modal__title-person-count').text(passengers.length);
		}
	},

	async bookingAuthorization(e) {
		if (e != null) {
			e.preventDefault();
		}
		const { reservations } = this.options.order;
		const $target = $(e.target);

		const reservationIndex = $target.closest('.b-order-reservation').attr('data-reservation-id');
		const reservation = reservations[reservationIndex];
		const reservationToApprove = reservation.uid;

		STATE.showLoader();
		try {
			this.disableElements(e);
			
			const {
				bookingToken,
				acceptProductDuplicatesMessageId,
			} = this.parentView.bookingDuplicatesHandler.getBookingDataObj();

			const result = await axios.post('/midoffice/ibecorp-b2b/booking/bookAuthorization', {
				parameters: {
					currentBooking: {
						uid: this.model.get('initiatePayment').get('bookingToPay'),
					},
					reservationUid: reservationToApprove,
					bookingToken,
					acceptProductDuplicatesMessageId,
				},
			});

			const responseFieldName = this.getBookingAuthorizationResponseObjNameByTravelSubject(reservation?.travelSubject?.uid);
			if (responseFieldName && result?.data?.result?.[responseFieldName]) {
				if (this.parentView.bookingDuplicatesHandler.shouldShowProductDuplicatesPopup(result.data.result[responseFieldName])) {
					STATE.hideLoader();
					this.parentView.bookingDuplicatesHandler.showProductDuplicatesPopup({
						...result.data.result[responseFieldName],
						onOk: () => this.bookingAuthorization(e), // <- событие нужно для того, чтобы с его target считать данные,
					});
					return result;
				}
			}

			const finish = () => window.location.reload();

			// no check for async actions - just show messages and do not interrupt the logic below
			if (result?.data?.result?.messages && !_.isEmpty(result.data.result.messages)) {
				const messages = result.data.result.messages;
				const popup = new Widgets.Popup({
					type: 'info',
					content: messages.length && _.map(messages, (m) => m.text).join('<br/>'),
					actions: [
						{
							label: L10N.get('Common.close'),
							action: () => {
								finish();
								popup.hide();
							},
						},
					],
				});
				popup.show();
				return this;
			}

			finish();
			return result;
		} catch (error) {
			STATE.hideLoader();
			this.reloadOnErrorPopupListener();
		}
		return this;
	},

	getBookingAuthorizationResponseObjNameByTravelSubject(travelSubject = '') {
		switch (travelSubject) {
			case 'AIR':
				return 'aviaBookingResult';
			case 'RAILWAY':
				return 'railBookingResult';
			case 'HOTEL_RESERVATION':
				return 'hotelBookingResult';
			case 'TRANSFER':
				return 'transferBookingResult';
			default:
				return '';
		}
	},

	async approveAuthorization(e) {
		if (e != null) {
			e.preventDefault();
		}
		const {reservations} = this.options.order;
		const $target = $(e.target);

		const reservationIndex = $target.closest('.b-order-reservation').attr('data-reservation-id');
		const reservation = reservations[reservationIndex];
		const reservationToApprove = reservation.uid;

		STATE.showLoader();
		try {
			this.disableElements(e);
			await axios.post('/midoffice/ibecorp-b2b/booking/approveAuthorization', {
				parameters: {
					currentBooking: {
						uid: this.model.get('initiatePayment').get('bookingToPay'),
					},
					reservationUid: reservationToApprove,
				},
			});
			window.location.reload();
		} catch (error) {
			STATE.hideLoader();
			Widgets.Popup.showUnknownErrorPopup();
		}
	},

	async refuseAuthorization(e) {
		if (e != null) {
			e.preventDefault();
		}
		const {reservations} = this.options.order;
		const $target = $(e.target);

		const reservationIndex = $target.closest('.b-order-reservation').attr('data-reservation-id');
		const reservation = reservations[reservationIndex];
		const reservationToApprove = reservation.uid;

		STATE.showLoader();
		try {
			this.disableElements(e);
			await axios.post('/midoffice/ibecorp-b2b/booking/refuseAuthorization', {
				parameters: {
					currentBooking: {
						uid: this.model.get('initiatePayment').get('bookingToPay'),
					},
					reservationUid: reservationToApprove,
				},
			});
			window.location.reload();
		} catch (error) {
			STATE.hideLoader();
			Widgets.Popup.showUnknownErrorPopup();
		}
	},

	async selectIntention(e) {
		if (e != null) {
			e.preventDefault();
		}
		const {reservations} = this.options.order;
		const $target = $(e.target);

		const ticketType = $target.attr(`data-type`);

		const reservationIndex = $target.closest('.b-order-reservation').attr('data-reservation-id');
		const reservation = reservations[reservationIndex];
		const reservationToApprove = reservation.uid;

		STATE.showLoader();
		try {
			this.disableElements(e);
			const response = await axios.post('/midoffice/ibecorp-b2b/booking/selectIntention', {
				parameters: {
					currentBooking: {
						uid: this.model.get('initiatePayment').get('bookingToPay'),
					},
					reservationUid: reservationToApprove,
				},
			});

			if (ticketType === 'AVIA' && response.data.result.newPrice && response.data.result.oldPrice && response.data.result.priceChanged === true) {
				const message = _.template(`
					<div>
						${L10N.get('cabinet.orders.actualPrice')}: <strong>
							<%= Formatter.formatPrice(newPrice) %>
						</strong>
						(${L10N.get('cabinet.orders.prevPrice')}: <%= Formatter.formatPrice(oldPrice) %>)
					</div>`);

				const repricePopup = new Widgets.Popup({
					content: message({
						newPrice: response.data.result.newPrice,
						oldPrice: response.data.result.oldPrice,
						Formatter,
					}),
					title: L10N.get('cabinet.orders.priceChanging'),
					closeOnlyOnAction: true,
					type: 'info',
					actions: [
						{
							label: L10N.get('cabinet.orders.close'),
							action: async () => {
								repricePopup.hide();
							},
						},
						{
							label: L10N.get('cabinet.orders.doBooking'),
							action: () => {
								repricePopup.hide();
								this.approvalIntentionAvia(response.data.result);
							},
						},
					],
				});
				repricePopup.show();
			} else if (response.data.result) {
				if (ticketType === 'AVIA') {
					this.approvalIntentionAvia(response.data.result);
				}
				if (ticketType === 'RAILWAY') {
					this.approvalIntentionRailway(response.data.result, reservation);
				}
				if (ticketType === 'HOTEL_RESERVATION') {
					this.approvalIntentionHotel(response.data.result, reservation);
				}
				if (ticketType === 'TRANSFER') {
					this.approvalIntentionTransfer(response.data.result, reservation);
				}
			}
		} catch (error) {
			Widgets.Popup.showUnknownErrorPopup();
		}

		STATE.hideLoader();
	},

	showOtherFlights(result) {
		const {issueAllowed, approvalAllowed, searchResultSortType, flights} = result;
		const {order, booking} = this.options;
		const serviceCount = _.reduce(order.reservations, (prev, acc) => {
			return prev + (acc.services || []).length;
		}, 0);
		STATE.setSearchResult({
			avia: [{flights}],
			issueAllowed,
			approvalAllowed,
			searchResultSortType,
			order: {number: order.number, bookingUid: booking.bookingInfo.bookingUid, serviceCount},
		});
		STORE.set(STATE.ROUTES.MAIN_SEARCH, STATE.getSearchModel().attributes, 14400);
		STATE.setFormState('avia:filter', null);
		STATE.navigate('avia/tickets');
	},

	approvalIntentionAvia(result) {
		if (result.aviaData.variantNotFound) {
			const popup = new Widgets.Popup({
				content: `<div>${L10N.get('cabinet.orders.offerNotAvailable')}</div>`,
				closeOnlyOnAction: true,
				type: 'info',
				actions: [
					{
						label: L10N.get('cabinet.orders.back'),
						action: () => {
							popup.hide();
						},
					},
					{
						label: L10N.get('searchForm.execSearch'),
						action: () => {
							popup.hide();
							this.showOtherFlights(result.aviaData);
						},
					},
				],
			});
			popup.show();
			return;
		}
		STORE.set(STATE.ROUTES.AVIA_PASSENGERS, {
			flightToken: result.aviaData.selectFlight.flightToken,
			isIntention: true,
			currentBooking: result.booking,
			reservationUid: result.reservationUid,
		}, 48 * 60);

		// clear previous passenger data
		STORE.remove(`${STATE.ROUTES.AVIA_PASSENGERS}_MODEL`);
		STATE.set(`${STATE.ROUTES.AVIA_PASSENGERS}_MODEL`, null);
		STATE.navigate(STATE.ROUTES.AVIA_PASSENGERS);
	},

	approvalIntentionRailway(result, reservation) {
		// обновляем параметры поиска
		const searchParams = STORE.get(STATE.ROUTES.MAIN_SEARCH) || {};
		const passengers = reservation.services.map((service) => {
			return service.travellers.map((traveller) => traveller?.person);
		});

		searchParams.passengers = passengers;
		STORE.set(STATE.ROUTES.MAIN_SEARCH, searchParams);

		STORE.set(`${STATE.ROUTES.RAILWAYS_PREVIEW}_INTENTION_DATA`, result, 48 * 60);
		STORE.set(`${STATE.ROUTES.RAILWAYS_PREVIEW}_INTENTION_DATA_RESERVATION`, reservation, 48 * 60);
		STATE.navigate(STATE.ROUTES.RAILWAYS_PREVIEW);
	},

	approvalIntentionHotel(result, reservation) {
		const data = {
			hotelCard: result.hotelData.hotelCard,
			reservation,
			showGdsAccountName: reservation.showGdsAccountName,
			currentBooking: {
				uid: this.model.get(`checkBooking`).bookingInfo.bookingUid,
			},
		};

		STORE.set(`${STATE.ROUTES.HOTELS_PREVIEW}_INTENTION_DATA`, data, 48 * 60);
		STATE.navigate(STATE.ROUTES.HOTELS_PREVIEW);
	},

	approvalIntentionTransfer(result, reservation) {
		const {transfer} = result.transferData;
		const passengersCount = reservation.services && reservation.services.length > 0 ? reservation.services[0].passengersCount : 0;
		const data = {
			tripInfo: {
				startPlace: {
					address: reservation.startAddress,
				},
				date: reservation.pickUpTime,
				endPlace: {
					address: reservation.endAddress,
				},
				subAddresses: reservation.subAddresses || [],
				transferType: reservation.transferType,
				passengersTypes: null,
				capacity: {uid: transfer.capacity, caption: L10N.getPluralForm('transfers.capacity', transfer.capacity)},
				carClass: transfer.carClass,
				travellersCount: passengersCount,
				token: transfer.token,
				flexibleTariff: reservation.flexibleTariff || false,
				flexibleTariffAgreement: reservation.flexibleTariffAgreement || false,
			},
			token: transfer.token,
			isIntention: true,
			reservationUid: reservation.uid,
			currentBooking: {
				uid: this.model.get(`checkBooking`).bookingInfo.bookingUid,
			},
		};

		STORE.set(`${STATE.ROUTES.TRANSFERS_PASSENGERS}`, data, 48 * 60);
		STATE.navigate(STATE.ROUTES.TRANSFERS_PASSENGERS);
	},

	createFinanceDocuments(e) {
		if (e instanceof $.Event) {
			e.preventDefault();
		}
		const contractType = e?.target?.dataset?.contractType || 'CLIENT';

		STATE.showLoader();
		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/cabinet/createFinanceDocuments', {
			parameters: {
				booking: {
					uid: this.model.get('initiatePayment').get('bookingToPay'),
					caption: '',
				},
				contractType,
			},
		}).then(() => {
			STATE.hideLoader();
			STATE.router.reloadPage();
		}).catch((err) => {
			STATE.hideLoader();
			if (axios.isCancel(err) || err?._ajaxErrorHandled) {
				logger.error(err);
			} else {
				throw new Error(err);
			}
		});
	},

	getFinanceDocuments(e) {
		if (e instanceof $.Event) {
			e.preventDefault();
		}

		const contractType = e?.target?.dataset?.contractType || 'CLIENT';

		STATE.showLoader();
		this.disableElements(e);
		axios.get('/midoffice/ibecorp-b2b/cabinet/getFinanceDocuments', {
			params: {
				bookingUid: this.model.get('initiatePayment').get('bookingToPay'),
				contractType,
			},
			responseType: 'arraybuffer',
		}, this.model).then((response) => {
			GlUl.downloadFile(response, 'financeDocuments', {type: 'application/pdf'});
		}).catch((err) => {
			STATE.hideLoader();
			if (axios.isCancel(err) || err?._ajaxErrorHandled) {
				logger.error(err);
			} else {
				throw new Error(err);
			}
		});
	},

	// Flight Receipt
	getFlightReceipt(e) {
		let selectedServices = this.model.get('selectedServicesForFlightReceipts');

		// Hack
		// If use the checkbox for all lines, function will get all services
		// filter it by certificateOfCompletedFlightCreationPossible
		selectedServices = selectedServices.filter((service) => service.aclEditGranted && service.certificateOfCompletedFlightCreationPossible);

		if (!selectedServices.length) return;

		const {booking} = this.options;

		STATE.showLoader();
		this.disableElements(e);
		axios.post('midoffice/ibecorp-b2b/booking/createCertificate', {
			parameters: {
				booking: {
					uid: booking.bookingInfo.bookingUid,
				},
				productUids: selectedServices.map((service) => service.uid),
			},
		}).then((response) => {
			STATE.hideLoader();

			if (response.statusText === 'No Content') {
				const popup = new Widgets.Popup({
					type: 'info',
					content: `
						<div class="b-popup-successful__content">
							${L10N.get('cabinet.successfulSavedPopup.receiptReady')}
						</div>
					`,
					actions: [{
						label: L10N.get('cabinet.orders.close'),
						action: () => {
							popup.hide();
							window.location.reload();
						},
					}],
				});
				popup.show();
			}
		}).catch((err) => {
			if (this.orderNotes != null && err) {
				this.orderNotes.getCorrespondence();
			}
			if (axios.isCancel(err)) {
				logger.error(err);
			} else {
				throw new Error(err);
			}
		});
	},

	downloadFlightReceipt(e) {
		const {booking} = this.options;
		const {reservations} = this.options.order;
		const $target = $(e.target);

		const reservationIndex = $target.closest('.b-order-reservation').attr('data-reservation-id');
		const reservation = reservations[reservationIndex];

		STATE.showLoader();
		this.disableElements(e);
		axios.get('/midoffice/ibecorp-b2b/cabinet/getCertificateOfCompletedFlight', {
			params: {
				booking: booking.bookingInfo.bookingUid,
				pnr: _.isArray(reservation.prn) ? reservation.prn[0].prn : reservation.prn,
			},
			responseType: 'arraybuffer',
		}, this.model).then((response) => {
			GlUl.downloadFile(response, 'flightReceipt', {type: 'application/pdf'});
		}).catch((err) => {
			STATE.hideLoader();
			if (axios.isCancel(err)) {
				logger.error(err);
			} else {
				throw new Error(err);
			}
		});
	},

	showBookingDocumentsWidget(e) {
		if (e) e.preventDefault();
		const renderBookingDocumentsModal = () => {
			if (this.bookingDocumentsModal == null) {
				this.bookingDocumentsModal = new BookingDocsModal({
					client: this.options.order.client,
					documents: this.bookingDocuments,
					currentBooking: this.options.booking.bookingInfo.bookingUid,
					autoSuggestEmails: this.model.get('autoSuggestEmails'),
				});
			} else {
				$('body').append(this.bookingDocumentsModal.render().$el);
			}
			this.syncTravelDocumentWidgetWidthPosition();
		};

		if (!this.bookingDocuments) {
			STATE.showLoader();
			axios.get('midoffice/ibecorp-b2b/cabin/getBookingDocuments', {
				params: {
					bookingUid: this.options.booking.bookingInfo.bookingUid,
				},
			}).then(res => {
				STATE.hideLoader();
				this.bookingDocuments = res.data.result;
				renderBookingDocumentsModal();
			}).catch((err) => {
				STATE.hideLoader();
				if (axios.isCancel(err)) {
					logger.error(err);
				} else {
					throw new Error(err);
				}
			});
		} else {
			renderBookingDocumentsModal();
		}
	},

	syncTravelDocumentWidgetWidthPosition() {
		if (this.bookingDocumentsModal != null && this.bookingDocumentsModal.$el.is(':visible') && this.$el instanceof $) {
			const paddingRight = $('.l-layout__wrapper').css('paddingRight');
			const width = parseInt(this.$el.outerWidth(), 10) + parseInt(paddingRight, 10);

			this.bookingDocumentsModal.ui.modal.css({width});
			this.bookingDocumentsModal.$el.css({paddingRight});
		}
	},

	openRuleCarrier(e) {
		let target = null;
		if (e instanceof $.Event) {
			e.preventDefault();
			target = $(e.currentTarget);
		}

		if (!target || !target.data('token')) return;

		const params = {
			bookingFile: this.options.booking.bookingInfo.bookingUid,
			reservationUid: target.data('token'),
		};

		GlUl.openRuleCarrier.call(this, e, params);
	},

	makeSafeData(order) {
		if (!order.reservations) order.reservations = [];
		const reservationUnsafeFields = [
			{type: {}, name: 'card'},
			{type: {}, name: 'price'},
			{type: {}, name: 'travelSubject'},
			{type: {}, name: 'status'},
			{type: [], name: 'services'},
		];

		const replaceUndefinedFields = (iteratee, fields) => {
			if (!_.isObject(iteratee)) return;

			_.each(fields, (field) => {
				for (let i = 0; i < iteratee.length; i++) {
					if (!iteratee[i]) iteratee[i] = {};
					if (!iteratee[i][field.name]) iteratee[i][field.name] = field.type;
				}
			});
		};
		replaceUndefinedFields(order.reservations, reservationUnsafeFields);
	},

	adjustMobileTemplate(matches) {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (_.isObject(matches)) matches = matches.matches;

			const $reservations = this.$('.b-order-reservation');
			const $orderFooter = this.$('.b-order__footer');

			if (STATE.checkSiteType('B2C')) $orderFooter[matches ? 'removeClass' : 'addClass']('b2c-view');

			_.each($reservations, (el) => {
				if (!el) return;
				const $reservation = this.$(el);
				const $headerRight = $reservation.find('.b-order__container-header-right');
				const $header = $reservation.find('.b-order__container-header-label');
				const $labelList = $reservation.find('.b-order-label__list');

				if (matches) {
					$header.prepend($headerRight.find('.b-checkbox'));
					$headerRight.prepend($labelList.find('.b-order-label__item:not(.b-order-label__route)'));
					$labelList.find('.b-order-label__item').show();
					this.adjustMobileFooterBtns();
					this.mobileOrderFooterControlsInit();
					this.mobileReservationFooterControlsInit();
				} else {
					$headerRight.append($header.find('.b-checkbox'));
					$labelList.append($headerRight.find('.b-order-label__item'));
				}
			});
		}, 100);
	},

	mobileReservationFooterControlsInit() {
		const $reservations = this.$('.b-order-reservation');

		_.each($reservations, (r) => {
			const $reservation = this.$(r);
			const $popupBtns = $reservation.find('.js-footer-controls-btn');
			const $controlsPopup = $reservation.find('.js-mobile-controls');
			let isMoreActions = false;
			_.each($popupBtns, (el) => {
				const $btn = this.$(el);
				const item = $btn.data('targetClass');
				if (this.$(item).parents($reservation).length > 0) {
					$btn.css({display: 'flex'});
					isMoreActions = true;
				} else {
					$btn.css({display: 'none'});
				}
			});

			const visiblePopupBtns = _.filter($popupBtns, (el) => this.$(el).css('display') === 'flex');
			if (visiblePopupBtns.length === 1 && this.$(visiblePopupBtns[0]).data('targetClass') === '.b-expand') {
				isMoreActions = false;
			}
			$controlsPopup.hide();
			if (isMoreActions) {
				this.$(r).find('.js-show-mobile-controls').removeClass('expand-only');
			}
		});
	},

	mobileOrderFooterControlsInit() {
		const $orderFooter = this.$('.b-block-container__footer-rightbar');
		let isHaveActions = false;
		const $popupBtns = $orderFooter.find('.js-footer-controls-btn');
		const $controlsPopup = $orderFooter.find('.js-mobile-controls');
		_.each($popupBtns, (el) => {
			const $btn = this.$(el);
			const item = $btn.data('targetClass');
			if (this.$(item).parents($orderFooter).length > 0) {
				$btn.css({display: 'flex'});
				isHaveActions = true;
			} else {
				$btn.css({display: 'none'});
			}
		});
		if (!isHaveActions) $orderFooter.find('.js-show-mobile-controls').hide();

		$controlsPopup.hide();
	},

	mobileFooterControlsHandler(e) {
		if (!e) return;
		const $target = $(e.target);
		const targetClass = $target.data('targetClass');
		let $container = $target.parents('.b-order-reservation');

		if (_.size($container) < 1) {
			$container = $target.parents('.b-block-container__footer-rightbar');
		}
		const $popup = $container.find('.js-mobile-controls');

		if ((!$target.hasClass('js-show-mobile-controls') && _.size($target.parent('.js-show-mobile-controls')) < 1) &&
			_.size($target.parents('.js-mobile-controls')) < 1) {
			this.$('.js-mobile-controls').hide();
			this.$('.js-show-mobile-controls').removeClass('open');
			return;
		}
		const $actionBtn = $container.find(targetClass).eq(0);

		if (_.size($actionBtn) > 0) {
			$actionBtn.click();
			$popup.hide();
			$popup.removeClass('open');
		}
	},

	mobileFooterControlsOutsideClickHandler(e) {
		if (!e) return;
		const $target = $(e.target);

		if ($target.hasClass('js-show-mobile-controls')) return;

		if ($target.closest('.js-mobile-controls').length === 0) {
			this.$('.js-mobile-controls').hide();
			this.$('.js-show-mobile-controls').removeClass('open');
		}
	},

	showMobileControls(e) {
		if (e) e.preventDefault();

		let $popup = this.$(e.target).parents('.b-order-reservation').find('.js-mobile-controls');

		if (!_.size($popup)) {
			$popup = this.$(e.target).parents('.b-block-container__footer-rightbar').find('.js-mobile-controls');
		}

		if (!_.size($popup)) return;

		const isExpandOnly = [this.$(e.target).hasClass('expand-only'), this.$(e.target).hasClass('expand-only--reverse')];
		if (isExpandOnly.includes(true)) {
			$popup.find('[data-target-class=".b-expand"]').click();
			this.$(e.target)
				.removeClass(isExpandOnly[0] === true ? 'expand-only' : 'expand-only--reverse')
				.addClass(isExpandOnly[0] === false ? 'expand-only' : 'expand-only--reverse');

			return;
		}

		if ($popup.hasClass('open')) {
			$popup.hide();
			$popup.removeClass('open');
		} else {
			$popup.show();
			$popup.addClass('open');
		}
	},

	adjustMobileFooterBtns() {
		const $pageFooterRightBar = this.$('.b-block-container__footer-rightbar');
		const $documentsPopupMobile = $pageFooterRightBar.find('.js-show-mobile-controls');
		const $addSelect = $pageFooterRightBar.find('.b-order__additional-select .select-styled');
		const $visibleBtn = _.find([this.$('.b-payment__authorization'), this.$('.b-payment__approval'), this.$('.b-order__payment-hidden')],
			(el) => this.$(el).is(':visible'));

		if (_.size($visibleBtn) > 0 && !this.options.order.addServiceDisabled) {
			$documentsPopupMobile.detach().appendTo($pageFooterRightBar);
			$documentsPopupMobile.children('span').eq(0).hide();
			$documentsPopupMobile.removeClass('second-btn');
			$addSelect.removeClass('border-right').addClass('border-right-none');
			if ($documentsPopupMobile.is(':visible')) {
				$visibleBtn.addClass('border-right-none');
			} else {
				$visibleBtn.addClass('border-left-none');
			}
		} else {
			$documentsPopupMobile.detach().prependTo($pageFooterRightBar);
			$documentsPopupMobile.children('span').eq(0).show();
			$documentsPopupMobile.addClass('second-btn');
			$addSelect.addClass('border-right').removeClass('border-right-none');
			if ($visibleBtn) {
				$visibleBtn.removeClass('border-right-none').removeClass('border-left-none');
			}
		}
	},

	switchLabelTab(e, tab = null, reservationId = null) {
		if (e) e.preventDefault();
		let $target;
		let $reservation;
		if (tab != null && reservationId != null) {
			$reservation = $(`.b-order-reservation[data-reservation-id="${reservationId}"]`);
			$target = $reservation.find(`.js-switch-tab[data-tab="${tab}"]`);
		} else {
			reservationId = $(e.currentTarget).data('reservationId');
			$reservation = $(`.b-order-reservation[data-reservation-id="${reservationId}"]`);
			$target = $(e.currentTarget);
			tab = $target.data('tab');
		}

		$reservation.find('.js-switch-tab').removeClass('active');
		$target.addClass('active');
		const $tabContentList = $reservation.find('[data-tab-content]');
		const $tabContent = $reservation.find(`[data-tab-content="${tab}"]`);

		$tabContentList.addClass('dn');
		$tabContent.removeClass('dn');
	},

	// ancillary fees

	toggleSelectedMcoServicesAvia(reservationIndex, e) {
		const model = this.selectedMcoServicesAvia[reservationIndex];
		const mcoCollection = _.omit(this.selectedMcoServicesAvia[reservationIndex].attributes, 'allServices');
		const $cancelAncFeesBtn = this.$('.js-cancelAncillaryFees');

		if (!_.isEmpty(e.changed) && e.changed.allServices != null) {
			const allSelected = (e.changed.allServices === true);

			_.each(mcoCollection, (value, key) => {
				const selected = (value.get('disabled') !== true) && allSelected;

				model.set(`${key}.selected`, selected);
				this.model.trigger(`change:selectedMcoServicesAvia.${reservationIndex}.${key}.selected`, value, selected);
			});
		} else {
			const allSelected = _.every(mcoCollection, (el) => el.get('selected') === true);

			model.set('allServices', allSelected, { silent: true });
			this.model.trigger(`change:selectedMcoServicesAvia.${reservationIndex}.allServices`, model, allSelected);
		}

		if (_.some(mcoCollection, (m) => m.get('selected') === true)) {
			$cancelAncFeesBtn.removeClass('disabled').removeAttr('disabled');
		} else {
			$cancelAncFeesBtn.addClass('disabled').attr('disabled', 'disabled');
		}
	},

	async getAncillaryFees(reservationUid) {
		STATE.showLoader();
		return axios.post('/midoffice/ibecorp-b2b/booking/ancillaryFees', {
			parameters: {
				currentBooking: this.options.booking.bookingInfo.bookingUid,
				reservationUid,
			},
		}).catch((err) => {
			STATE.hideLoader();
			if (axios.isCancel(err)) {
				logger.error(err);
			} else {
				throw new Error(err);
			}
		});
	},

	async cancelAncillaryFees(e) {
		if (!e) return;
		const {order} = this.options;
		const reservationUid = $(e.currentTarget).data('reservationUid');
		const reservation = _.find(order.reservations, (r) => r.uid === reservationUid);
		const mcoUids = [];
		const mcoCollection = _.omit(this.selectedMcoServicesAvia[reservation._index].attributes, 'allServices');

		_.each(mcoCollection, (model, key) => {
			if (model.get('selected') === true) mcoUids.push(key);
		});

		await this._cancelAncillaryFees({reservationUid, mcoUids}).then(() => {
			STATE.router.reloadPage();
			this.selectedTab = 'ANC_FEES';

			setTimeout(() => {
				this.switchLabelTab(null, 'ANC_FEES', reservation._index);
			}, 1000);
		});
	},

	async _cancelAncillaryFees({reservationUid, mcoUids}) {
		STATE.showLoader();
		return axios.post('/midoffice/ibecorp-b2b/booking/ancillaryFees/cancel', {
			parameters: {
				currentBooking: this.options.booking.bookingInfo.bookingUid,
				reservationUid,
				mcoUids,
			},
		}).catch((err) => {
			STATE.hideLoader();
			if (axios.isCancel(err)) {
				logger.error(err);
			} else {
				throw new Error(err);
			}
		});
	},

	async showAncillaryFeesModal(e) {
		if (!e) return;
		e.preventDefault();
		const showNotAvailableFeesMessage = () => {
			this.$('.js-empty-anc-fees-message').html(`${L10N.get('cabinet.orders.noAvailableAncFees')}`);
			$(e.currentTarget).addClass('disabled').attr('disabled', 'disabled');
		};
		const reservationUid = $(e.currentTarget).data('reservationUid');
		const reservation = _.find(this.options.order.reservations, (r) => r.uid === reservationUid);
		if (!reservation.getAncillaryFeesAvailable) {
			showNotAvailableFeesMessage();
			return;
		}

		let travellers = [];

		_.each(reservation.services, (s) => {
			travellers.push(s.travellers);
		});

		travellers = _.uniq(_.flatten(travellers));

		const ancillaryFees = await this.getAncillaryFees(reservationUid).then(res => {
			return res.data.result;
		});
		STATE.hideLoader();

		if (_.isEmpty(ancillaryFees) || _.isEmpty(travellers)) {
			showNotAvailableFeesMessage();
			return;
		}

		this.ancillaryFeesView = new AncillaryFeesView({
			reservation,
			ancillaryFees,
			travellers,
			currentBooking: this.options.booking.bookingInfo.bookingUid,
			parent: this,
		});
	},

	copyToClipboard(e) {
		if (e) e.preventDefault();
		const selectedReservations = _.filter(this.options.order.reservations, (r) => this.selectedReservation[r._index] != null);
		let result = '';
		result += `${L10N.get('cabinet.orders.orderNumber')}: ${this.options.order.number}\n`;
		_.each(selectedReservations, (r) => {
			if (r.status && r.status.uid === 'ERROR') return;
			const $container = this.$el.find(`[data-reservation-id="${r._index}"]`);
			let reservationResult = '';
			reservationResult += `${L10N.get('cabinet.orders.reservationPnr')}: ${r.prn}\n`;
			reservationResult += `${L10N.get('cabinet.orders.serviceName')}: ${r.travelSubject && r.travelSubject.caption}\n`;
			reservationResult += `${L10N.get('cabinet.orders.hotelStatus')}: ${r.status && r.status.caption}\n`;
			reservationResult += GlUl.gatherTextToCopyToClipBoard($container);
			result += `${reservationResult}\n`;
			logger.debug(reservationResult);
		});
		logger.debug(result);
		GlUl.copyToClipboard(result);
	},

	getServiceByReservationNServiceUid(reservationUid, serviceUid) {
		let output = null;
		if (!reservationUid || !serviceUid) return output;
		const reservation = this?.options?.order?.reservations?.find(r => r.uid === reservationUid);
		if (!reservation) return output;
		output = reservation?.services?.find(s => s.uid === serviceUid) || null;
		return output;
	},

	openAuthStepsModal(e) {
		e?.preventDefault?.();
		this.closeAuthStepsModalView();
		const reservationUid = e.currentTarget.dataset.reservationUid;
		const serviceUid = e.currentTarget.dataset.serviceUid;
		const service = this.getServiceByReservationNServiceUid(reservationUid, serviceUid);
		if (service !== null) {
			this.authStepsModalView = new AuthorizationStepsModalView({
				parentView: this,
				service,
			});
		}
	},

	closeAuthStepsModalView() {
		if (this.authStepsModalView) this.authStepsModalView.close();
	},

	remove(...args) {
		if (this.ancillaryFeesView) this.ancillaryFeesView.close();
		this.closeAuthStepsModalView();
		BaseView.prototype.preinitialize.apply(this, args);
	},

});
